import React, { lazy } from "react"
import { Switch } from "react-router-dom"
import { ThemeProvider } from "styled-components/macro"

import { THEMES } from "../shared/consts/themes"
import Overview from "./pages/Overview"
import { Route } from "./pages/Overview/animations"
import { IkBen, IkWas, IkWord } from "./pages/Overview/FirstPages"

import { useStore } from "../shared/store/store"
import useBeforeUnload from "../shared/utils/useBeforeUnload"
import { Code } from "./pages/Code"
import { CodeExpired } from "./pages/CodeExpired"
import { FillInCode } from "./pages/FillInCode"

const Introductions = lazy(() => import("../game/pages/Intro"))

const lazyDelayed = (promise, ms = 1100) => {
  return React.lazy(() =>
    Promise.all([promise(), new Promise((resolve) => setTimeout(resolve, ms))])
    .then(([moduleExports]) => moduleExports)
  )
}

const Assignment1 = lazyDelayed(() => import("./assignments/Assignment1"))
const Assignment2 = lazyDelayed(() => import("./assignments/Assignment2"))
const Assignment3 = lazyDelayed(() => import("./assignments/Assignment3"))
const Assignment4 = lazyDelayed(() => import("./assignments/Assignment4"))
const Assignment5 = lazyDelayed(() => import("./assignments/Assignment5"))
const Assignment6 = lazyDelayed(() => import("./assignments/Assignment6"))
const Assignment7 = lazyDelayed(() => import("./assignments/Assignment7"))
const Assignment8 = lazyDelayed(() => import("./assignments/Assignment8"))
const Assignment9 = lazyDelayed(() => import("./assignments/Assignment9"))
const Assignment10 = lazyDelayed(() => import("./assignments/Assignment10"))
const Assignment11 = lazyDelayed(() => import("./assignments/Assignment11"))
const Assignment13 = lazyDelayed(() => import("./assignments/Assignment13"))

function WaitingComponent({ Component, Fallback, ...props }) {
  useBeforeUnload()
  return (
    <React.Suspense fallback={<Fallback />}>
      <Component {...props} />
    </React.Suspense>
  )
}

export const Game = ({ user, baseURL, isMobile }) => {
  const { code, isValid, setValid } = useStore()
  // if (!isMobile && !user) {
  //   return <>Wordt alleen ondersteund op mobiel</>
  // }
  // if (!isMobile) {
  //   return (
  //     <Switch>
  //       <Route exact path="/">
  //         <Redirect
  //           to={{ pathname: "/dashboard", state: "De app is niet te " }}
  //         />
  //       </Route>
  //       <Route path="/">
  //         <MobileOnly />
  //       </Route>
  //     </Switch>
  //   );
  // }
  if (!isValid) {
    return (
      <Switch>
        <Route path="/expired">
          <CodeExpired />
        </Route>
        <Route path="/overview">
          <FillInCode setValid={setValid} />
        </Route>
      </Switch>
    )
  }
  return (
    <>
      <Route exact path="/overview">
        <Overview user={user} />
      </Route>
      <Route exact path="/code">
        <Code code={code} baseURL={baseURL} />
      </Route>
      <Route path="/assignment1">
        <ThemeProvider theme={THEMES[1]}>
          <WaitingComponent Component={Assignment1} Fallback={IkWas} />
        </ThemeProvider>
      </Route>
      <Route path="/assignment2">
        <ThemeProvider theme={THEMES[2]}>
          <WaitingComponent Component={Assignment2} Fallback={IkWas} />
        </ThemeProvider>
      </Route>
      <Route path="/assignment3">
        <ThemeProvider theme={THEMES[3]}>
          <WaitingComponent Component={Assignment3} Fallback={IkWas} />
        </ThemeProvider>
      </Route>
      <Route path="/assignment4">
        <ThemeProvider theme={THEMES[4]}>
          <WaitingComponent Component={Assignment4} Fallback={IkBen} />
        </ThemeProvider>
      </Route>
      <Route path="/assignment5">
        <ThemeProvider theme={THEMES[5]}>
          <WaitingComponent Component={Assignment5} Fallback={IkBen} />
        </ThemeProvider>
      </Route>
      <Route path="/assignment6">
        <ThemeProvider theme={THEMES[6]}>
          <WaitingComponent Component={Assignment6} Fallback={IkBen} />
        </ThemeProvider>
      </Route>
      <Route path="/assignment7">
        <ThemeProvider theme={THEMES[7]}>
          <WaitingComponent Component={Assignment7} Fallback={IkWord} />
        </ThemeProvider>
      </Route>
      <Route path="/assignment8">
        <ThemeProvider theme={THEMES[8]}>
          <WaitingComponent Component={Assignment8} Fallback={IkBen} />
        </ThemeProvider>
      </Route>
      <Route path="/assignment9">
        <ThemeProvider theme={THEMES[9]}>
          <WaitingComponent Component={Assignment9} Fallback={IkBen} />
        </ThemeProvider>
      </Route>
      <Route path="/assignment10">
        <ThemeProvider theme={THEMES[10]}>
          <WaitingComponent Component={Assignment10} Fallback={IkBen} />
        </ThemeProvider>
      </Route>
      <Route path="/assignment11">
        <ThemeProvider theme={THEMES[11]}>
          <WaitingComponent Component={Assignment11} Fallback={IkBen} />
        </ThemeProvider>
      </Route>
      <Route path="/assignment13">
        <ThemeProvider theme={THEMES[13]}>
          <WaitingComponent Component={Assignment13} Fallback={IkWord} />
        </ThemeProvider>
      </Route>
      <Route exact path="/">
        <ThemeProvider theme={THEMES["intro"]}>
          <WaitingComponent Component={Introductions} Fallback={IkWord} />
        </ThemeProvider>
      </Route>
    </>
  )
}
