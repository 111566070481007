import React from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { Tile } from "./Tile";
import { THEMES } from "../../../shared/consts/themes";
import HomeButton from '../../components/HomeButton';
import QrButton from '../../components/QrButton';
import QuestionButton from '../../components/QuestionButton';
import { MaterialStandard } from "../../../shared/consts/ease";

import plantImg from "./assets/plant.png";
import titImg from "./assets/tit.png";
import joystickImg from "./assets/joystick.png";
import nametagImg from './assets/nametag.png';
import wheelImg from './assets/wheel.png';
import earImg from "./assets/ear.png";
import fistImg from './assets/fist.png';
import trafficLightImg from './assets/trafficlight.png';
import brainImg from './assets/brain.png';
import whiskImg from './assets/whisk.png';
import sheepImg from './assets/sheep.png';
import cupcakeImg from "./assets/cupcake.png";

import {
  IkBenImage,
  IkWasImage,
  IkWordImage
} from "../Overview/FirstPages";

import * as IDS from '../../../shared/consts/assignments'

const Wrapper = styled.div`
  overflow: hidden;
  flex: 0 0 auto;
`;

const reveal = (status, delay = 0) => css`
  ${status === "entered" || status === "entering"
    ? css`
        opacity: 0;
        animation: ${keyframes`
          to {
            opacity: 1;
          }
        `} 300ms forwards;
        animation-delay: ${p => delay}ms;
      `
    : undefined}
`;

const GreatDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: auto;
`;

const Header = styled.div`
  padding-top: 100px;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 135px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #333333;
  font-family: "Work Sans";
  font-size: 30px;
  font-weight: 300;
  margin: 0;
  ${p => reveal(p.theme.pageStatus, p.delay)}
`;

const Subtitle = styled.h2`
  color: #333333;
  font-family: "Work Sans";
  font-size: 15px;
  font-weight: 300;
  font-style: italic;
  margin: 0;
  ${p => reveal(p.theme.pageStatus, p.delay)}
`;

const StyledTable = styled.div`
  padding: 0;
  display: grid;
  /* grid-gap: 5px; */
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
`;

const FooterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  bottom: 0;
  left: 0;
  right: 0;
`;



const Assignments = [];

export default function Overview({ user }) {
  let t = 0;
  return (
    <GreatDiv>

      <Helmet>
        <link rel="prefetch" href={IkBenImage} />
        <link rel="prefetch" href={IkWasImage} />
        <link rel="prefetch" href={IkWordImage} />
      </Helmet>

      <Header>
        <QrButton />
        {/* <QuestionButton color="#333333"  /> */}
        <HomeButton isAssignmentButton={!user} />
        <Title delay={t += 150}>{user ? `Welkom leerkracht` : `Welkom bij Humania`}</Title>
        <Subtitle delay={(t += 300)}>Waar wil je beginnen?</Subtitle>
      </Header>

      <StyledTable>
        <Tile
          id={IDS.ASSIGNMENT1}
          img={plantImg}
          to="/assignment1"
          delay={t += 300}
          backgroundColor={THEMES[1].backgroundColor}
        />
        <Tile
          id={IDS.ASSIGNMENT2}
          img={titImg}
          to="/assignment2"
          delay={t + 60 * 1}
          backgroundColor={THEMES[2].backgroundColor}
        />
        <Tile
          id={IDS.ASSIGNMENT7}
          img={joystickImg}
          to="/assignment7"
          delay={t + 60 * 2}
          backgroundColor={THEMES[7].backgroundColor}
        />

        <Tile
          id={IDS.ASSIGNMENT3}
          img={nametagImg}
          to="/assignment3"
          delay={t + 60 * 2}
          backgroundColor={THEMES[3].backgroundColor}
        />
        <Tile
          id={IDS.ASSIGNMENT4}
          img={trafficLightImg}
          to="/assignment4"
          delay={t + 60 * 1}
          backgroundColor={THEMES[4].backgroundColor}
        />
        <Tile
          id={IDS.ASSIGNMENT13}
          img={wheelImg}
          to="/assignment13"
          delay={t + 60 * 6}
          backgroundColor={THEMES[13].backgroundColor}
        />

        <Tile
          id={IDS.ASSIGNMENT5}
          img={earImg}
          to="/assignment5"
          delay={t + 60 * 2}
          backgroundColor={THEMES[5].backgroundColor}
        />
        <Tile
          id={IDS.ASSIGNMENT6}
          img={fistImg}
          to="/assignment6"
          delay={t + 60 * 3}
          backgroundColor={THEMES[6].backgroundColor}
        />
        <Tile
          id={IDS.ASSIGNMENT8}
          img={brainImg}
          to="/assignment8"
          delay={t + 60 * 3}
          backgroundColor={THEMES[8].backgroundColor}
        />

        <Tile
          id={IDS.ASSIGNMENT9}
          img={whiskImg}
          to="/assignment9"
          delay={t + 60 * 4}
          backgroundColor={THEMES[9].backgroundColor}
        />
        <Tile
          id={IDS.ASSIGNMENT10}
          img={sheepImg}
          to="/assignment10"
          delay={t + 60 * 4}
          backgroundColor={THEMES[10].backgroundColor}
        />
        <Tile
          id={IDS.ASSIGNMENT11}
          img={cupcakeImg}
          to="/assignment11"
          delay={t + 60 * 5}
          backgroundColor={THEMES[11].backgroundColor}
        />
      </StyledTable>
      {/* <FooterDiv>
        <Link to="/" backgroundColor={`#333333`} isIndex>Terug naar het begin</Link>
      </FooterDiv> */}
    </GreatDiv>
  );
}
