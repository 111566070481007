import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { Context } from '../../../shared/store/store';

const Modal = styled.div`
  display: ${p => p.isHidden ? `none` : `flex`};
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: auto;
  overflow: auto;
  color: #000000;
  background-color: #ffffff;

  p, h1, b {
    font-family: "Work Sans";
    color: #333333;
    text-align: center;
    max-width: 80%;
    margin-bottom: 10px;
    margin-right: 13px;
    margin-left: 13px;
  }

  p{
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  b{
    font-family: "Work Sans";
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
  margin: 0;
  font-size: 28.8px;
  margin-top:${p => p.marginTop};
`;

const CrossSVG = styled.svg`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 21px;
  fill: none;
  stroke: #333333;
  stroke-width: 2;
  stroke-linecap: round;
`;

const Cross = () => (
  <CrossSVG viewBox="0 0 42 42">
    <line x1="39.6" y1="2.4" x2="2.4" y2="39.6" />
    <line x1="39.6" y1="39.6" x2="2.4" y2="2.4" />
  </CrossSVG>
);

const Space = styled.div`
  flex: 1;
  min-height: ${p=>p.minHeight || `20px`};
`;

const Img = styled.img`
  box-sizing: border-box;
  border-radius: 50%;
  width: 100%;
  height: 100%;

  border-style: solid;
  border-width: 3px;
  border-color: #ffffff;
  line-height: 0;
`;

const Border = styled.div`
  box-sizing: border-box;
  border: #333333 solid 2px;
  border-radius: 50%;
  width: 295px;
  height: 295px;
  line-height: 0;
`;

const QuestionSVG = styled.svg`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;

	fill:none;
  stroke: ${p=> p.color || p.theme.color};
  stroke-width:2;
  stroke-linecap:round;
`;

const StyledButton = styled.button`
  height: 40px;
  border: 2px solid #333333;
  color: #333333;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 31.33px;
  text-transform: uppercase;
  background: none;
  padding: 0 28px;
  outline: none;

  flex: 0 0 auto;
  margin-bottom: 20px;
`;

const QuestionMark = ({ color }) => (  
  <QuestionSVG color={color} viewBox="0 0 42 42">
    <g>
      <circle cx="21" cy="21" r="18.6"/>
      <g>
        <path d="M15.1,16.6c0-3.2,2.6-5.9,5.9-5.9s5.9,2.6,5.9,5.9c0,0,0,0,0,0.1c0,2.3-1.4,4.4-3.6,5.4
          c-2,0.9-3.3,2.8-3.3,5.1v0.9"/>
        <line x1="20" y1="32.3" x2="20" y2="32.3"/>
      </g>
    </g>
  </QuestionSVG>
);


export default ({ exhibitImg, subject, modalTitle, modalText, ...props }) => {
  const { isModalVisible, setModalVisible } = useContext(Context)
  return (
    <>
      <Modal isHidden={!isModalVisible}>
        <div onClick={() => setModalVisible(!isModalVisible)}>
          <Cross />
        </div>
        <Title marginTop="60px" marginBottom="10px">Waar gaat deze exhibit over?</Title>
        <Border>
          <Img src={exhibitImg} />
        </Border>
        <b>Onderwerp</b>
        <p>{subject}</p>
        <Space height="15px"/>
        <Title>{modalTitle}</Title>
        <p>
          {modalText}
        </p>
        <Space/>
        <StyledButton onClick={() => setModalVisible(false)}>Sluiten</StyledButton>
      </Modal>
      <div onClick={()=>setModalVisible(!isModalVisible)}>
        <QuestionMark {...props}/>
      </div>
    </>
  )
}
