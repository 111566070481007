import React from "react";
import styled, { css } from "styled-components/macro";
import { RatioContainer } from "../../shared/components/RatioContainer";

const Border = styled("div")`
  border: 2px solid #333333;
  padding: 8px;
  border-radius: 50%;
  margin-bottom: 1vw;
  line-height: 0;
`;

const Img = styled("img")`
  width: 100%;
  height: auto;
  border-radius: 50%;
`;

export const Circle = ({ className, size, ...props }) => {
  return (
    <Border className={className}>
      <RatioContainer>
        <Img {...props} />
      </RatioContainer>
    </Border>
  );
};
