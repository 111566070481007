import React from 'react'
import { Content, Subtitle } from './Content'
import styled from 'styled-components'

const Container = styled(Content)`
  text-align: center;
  justify-content: center;
`

export const NoData = (props) => (
  <Container {...props}>
    <Subtitle>Geen data om te tonen</Subtitle>
  </Container>
)