import styled, { css } from "styled-components/macro";
import { CENTER_CONTENT, MOBILE_BREAKPOINT } from "../../shared/consts/css-snippets";

export const Button = styled("a")`
  box-sizing: border-box;
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  min-height: 50px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
  ${CENTER_CONTENT};

  text-decoration: none;

  ${p => p.disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}

  ${p =>
    p.primary
      ? css`
          color: #ffffff;
          background-color: #333333;
          border: none;
        `
      : css`
          color: #333333;
          background-color: #ffffff;
          border: 2px solid #333333;
        `}
`;
