import React, { useMemo } from "react";
import useFetch from "fetch-suspense";
import { PieChart, Pie, ResponsiveContainer, Legend } from "recharts";
import styled from "styled-components";

import { Content, Subtitle } from "../components/Content";
import { Box } from "../components/Box";
import { Circle } from "../components/Circle";
import { Bubble } from "../components/Bubble";
import { RatioContainer } from "../../shared/components/RatioContainer";

import { useStore } from "../store";

import IntroImage from "../../game/assignments/Assignment6/assets/route3.jpg";
import { BLUE, RED } from "../consts/colors";
import { InfoBox } from "../components/InfoBox";
import { SVGText } from "../components/SVGText";
import { NoData } from "../components/NoData";

import { formatNumber } from '../../shared/utils/formatNumber'

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props) => {
  const {
    cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload
  } = props
  const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <SVGText x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${payload.payload.name} ${payload.payload.value}%`}
    </SVGText>
  );
};

const Graph = styled(RatioContainer)`
  margin: -30px;
`

const TITLE = "Lichamelijk (on)mogelijk"

export const Assignment6_1 = () => {
  const { user } = useStore();
  const stats = useFetch(`/api/stats/6/${user.code}`);
  const data = useMemo(
    () => [
      {
        name: "Rechts",
        value: stats.school?.right_handed,
        fill: BLUE[0]
      },
      {
        name: "Links",
        value: 100 - stats.school?.right_handed,
        fill: RED[0]
      }
    ],
    [stats]
  );
  
  if (!stats.school) return <NoData title={TITLE} />

  return (
    <Content
      grid={6}
      title={TITLE}
      // subtitle="Links- en rechtshandigen in de klas"
    >
      <Box size={3} center>
        <Graph ratio={0.8}>
          <ResponsiveContainer>
            <PieChart width={300} height={300}>
              <Pie
                stroke={0}
                dataKey="value"
                data={data}
                label={renderCustomizedLabel}
                labelLine={false}
                // label={({ percent, name, ...props }) => (
                  //   <text {...props}>{`${100 * percent}%`}</text>
                  // )}
                  />
              {/* <Legend font-size={12} cy={300} layout="vertical" /> */}
            </PieChart>
          </ResponsiveContainer>
        </Graph>
        <Subtitle>Links- en rechtshandigen in de klas</Subtitle>
      </Box>
      <Bubble
        top={{
          value: formatNumber(stats.school.cm) + "cm",
          label: "Gemiddelde lenigheid van de klas"
        }}
        bottom={{
          value: formatNumber(stats.school.best) + "cm",
          label: "Meest lenige van de klas"
        }}
      />
      <InfoBox size={2}>
        <Circle src={IntroImage} />
        <h3>Een gemiddeld lichaam</h3>
        <p>
          Dit is een verdeling van de rechts- en linkshandigheid van de klas. En
          hoe lenig jullie gemiddeld zijn, gymmen jullie genoeg?
        </p>
      </InfoBox>
    </Content>
  );
};
