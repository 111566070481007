import styled from 'styled-components/macro';
import reveal from '../animations/reveal';
import { opacity } from '../animations/opacity';

const Footer = styled.div`
  align-items: center;
  justify-content: center;
  /* background-color: ${p => p.isDiscrete ? "#999999" : p.theme.backgroundColor}; */
  color: #ffffff;
  display: flex;
  font-family: "Work Sans";
  font-size: 12px;
  padding: 0 14px;
  padding-bottom: 26px;
  font-weight: 500;
  line-height: 30px;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`;

export const FooterDescription = styled.div`
  flex: 1;
  text-align: center;
  user-select: none;
`;

export default Footer
