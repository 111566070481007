import React, { useContext } from 'react'
import styled from 'styled-components/macro';
// import reveal from './animations/reveal';
import PrevButton from './PrevButton';
import { opacity } from "../animations/opacity";
import HomeButton from './HomeButton';
import { Context } from '../../shared/store/store';
import QuestionButton from './QuestionButton';

const Header = styled.div`
  align-items: center;
  color: ${p => p.color || p.theme.color};
  fill: ${p => p.color || p.theme.color};
  display: flex;
  font-family: "Work Sans";
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 14px;
  padding-top: 15px;
  font-weight: 500;
  line-height: 30px;
  z-index: 999;
  ${p => opacity(p.theme.pageStatus)}
  animation-delay: 600ms;

`;

export default ({ step, setStep, className, color, modalProps }) => {
  const { user } = useContext(Context)
  return (
    <Header className={className} step={step}>
      <PrevButton color={color} setStep={setStep} isFirst={step === 0} />
      <QuestionButton color={color} {...modalProps} />
      <HomeButton isAssignmentButton color={color} />
    </Header>);
}
