import React, { useState } from "react";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";

const StyledSpan = styled.span`
  text-decoration: underline;
`;

const fade = keyframes`
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
`;

const Notification = styled.div`
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 40px;
  background-color: green;
  color: white;
  animation: ${fade} 3s 2s;
`;

const CopyURL = ({ url }) => {
  const [isVisible, setVisibility] = useState(false);

  const handleClick = () => {
    if (window.isSecureContext) {
      return new Promise((resolve, reject) =>
        navigator.clipboard.writeText(url).then(() => setVisibility(true))
      );
    }
  };
  return (
    <>
      <StyledSpan onClick={() => handleClick()}>{url}</StyledSpan>
      {isVisible &&
        createPortal(
          <Notification onAnimationEnd={() => setVisibility(false)}>
            {url} gekopieerd naar clipboard
          </Notification>,
          document.getElementById("root")
        )}
    </>
  );
};

export default CopyURL;
