import React from 'react';
import styled from 'styled-components/macro';

const RowDiv = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-evenly;
  margin-top: ${p => p.marginTop};
  margin-bottom: ${p => p.marginBottom};
  flex: 0 0 auto;
`;

export default ({ ...props }) => (
  <RowDiv {...props} />
)