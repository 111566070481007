import styled from "styled-components";

export const SVGText = styled("text")`
  font-size: 18px;
  /* text-anchor: middle; */

  @media (min-height: 1000px) and (min-width: 1330px) {
    font-size: 22px;
  }
`;
