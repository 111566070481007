import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import { CENTER_CONTENT } from "../../../shared/consts/css-snippets";

import { useScrollOffset } from "../../components/Container";

const Offsets = [
  "-10px",
  "10px",
]

const SVG = styled.svg`
  fill:none;
  stroke-width:3;
  stroke-linecap:round;
`;

const Wrapper = styled("div")`
  position: fixed;
  bottom: 10px;
  ${CENTER_CONTENT}

  transition: opacity 0.5s;
  opacity: ${p => p.offset < 10 ? 1 : 0};
  pointer-events: none;

  svg {
    transition: transform 0.5s linear;
    transform: translateY(${p => Offsets[p.index]});

    width: 50px;
    height: 60px;
  }

  polyline {
    stroke: ${p => p.theme.color};
  }
`;

export const ScrollIndicator = () => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const t = setTimeout(() => {
      setIndex(i => (i >= Offsets.length - 1 ? 0 : i + 1));
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }, [index]);

  const offset = useScrollOffset()

  return (
    <Wrapper index={index} offset={offset}>
      <SVG viewBox="0 0 100 60" >
        <polyline className="st0" points="94,6.5 50,53.5 6,6.5" />
      </SVG>
    </Wrapper>
  );
};
