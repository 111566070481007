import React from "react";
import useFetch from "fetch-suspense";

import { Content } from "../components/Content";
import { Box } from "../components/Box";
import { Circle } from "../components/Circle";
import { InfoBox } from "../components/InfoBox";

import IntroImage from "../../game/assignments/Assignment3/exhibit.png";
import { Bubble } from "../components/Bubble";
import { useStore } from "../store";
import { NoData } from "../components/NoData";

export const Assignment3 = () => {
  const { user } = useStore();
  const stats = useFetch(`/api/stats/3/${user.code}`);
  if (!stats?.school) return <NoData title="Typisch Tom" />
  return (
    <Content grid={6} title="Typisch Tom">
      <Bubble
        top={{ value: stats.most_wanted, label: "Meest gezochte naam" }}
        // bottom={{ value: stats.least_wanted, label: "Minst gezochte naam" }}
      />
      <Bubble
        // top={{ value: stats.most_wanted, label: "Meest gezochte naam" }}
        top={{ value: stats.least_wanted, label: "Minst gezochte naam" }}
      />
      <Bubble
        top={{ value: stats.unique, label: "Aantal uniek gevonden namen" }}
        // bottom={{ value: stats.amount, label: "Totaal aantal gezochte namen" }}
      />
      <Bubble
        // top={{ value: stats.unique, label: "Aantal uniek gevonden namen" }}
        top={{ value: stats.amount, label: "Totaal aantal gezochte namen" }}
      />
      {/* <Box size={2} /> */}
      <InfoBox size={2}>
        <Circle src={IntroImage} />
        <h3>Modern of traditioneel</h3>
        <p>
          Dit is het zoekgedrag van de klas in de namendatabase. Hebben jullie
          je naam in de exhibit gevonden? Was die modern of traditioneel?
        </p>
      </InfoBox>
    </Content>
  );
};
