import React from 'react'
import styled from "styled-components/macro";

const Container = styled("div")`
  width: 100%;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-bottom: ${p => (p.ratio || 1) * 100}%;
  }
`;

const Content = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center; 
`

export const RatioContainer = ({ratio, className, ...props}) => {
  return (
    <Container ratio={ratio} className={className}>
      <Content {...props} />
    </Container>
  );
};
