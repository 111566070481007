import React from "react";
import styled from "styled-components/macro";
import Qr from "qrcode.react";
import { Link } from "react-router-dom";

import Content from "../../game/components/Content";
import CloseButton from '../../game/components/CloseButton';
import CopyURL from './CopyURL';
import { RatioContainer } from "../../shared/components/RatioContainer"

const StyledContent = styled(Content)`
  max-width: 640px;
`

const CenteringDiv = styled.div`
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
`;

const Title = styled.h1`
  color: #333333;
  font-family: "Work Sans";
  font-size: 30px;
  font-weight: 300;
  line-height: 72px;
  margin: 0;
`;

const StyledRatioContainer = styled(RatioContainer)`
  max-height: calc(100vh - 380px);
  max-width: calc(100vh - 380px);
`

const QrWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
`

export const Code = ({ code, baseURL, closeURL }) => {
  return (
    <StyledContent color="#ffffff">
      <CloseButton to={closeURL} left="20px" />
      <CenteringDiv>
        <Title>QR Klassencode</Title>
        <QrWrapper>
          <StyledRatioContainer>
              <Qr
                value={`${baseURL}/code/${code}`}
                size={500}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
          </StyledRatioContainer>
        </QrWrapper>
        <p>Iemand toevoegen? Scan deze klassencode met een camera of QR-code scanner.</p>
        <p>&nbsp;</p>
        <p>Of ga naar <CopyURL url={baseURL} /><br /> en voer de klassencode <b>{code}</b> in.</p>
      </CenteringDiv>
    </StyledContent>
  );
};
