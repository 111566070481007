import React from "react";
import { GREY } from "../consts/colors";

import { SVGText } from './SVGText';

export const SVGImage = ({x, y, radius, img, label, id}) => {
  return (
    <g>
      <defs>
        <circle id={`rect${id}`} cx={x} cy={y} r={radius} />
        <clipPath id={`clip${id}`}>
          <use xlinkHref={`#rect${id}`} />
        </clipPath>
      </defs>

      <circle
        cx={x}
        cy={y}
        r={radius + 2}
        fill="none"
        stroke-width="1"
        stroke={GREY}
      />

      {/* <use xlinkHref={`#rect${id}`} stroke-width="2" stroke="black"/> */}
      <image
        href={img}
        clip-path={`url(#clip${id})`}
        x={x - radius}
        y={y - radius}
        width={radius * 2}
        height={radius * 2}
      />
      <SVGText
        x={x}
        y={y + radius * 2}
        textAnchor="middle"
        fill={GREY}
      >
        {label}
      </SVGText>
    </g>
  );
};
