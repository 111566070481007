
import styled from 'styled-components/macro'

export const Box = styled('div')`
  height: ${p => p.height ?? "auto"};
  flex: 0 0 ${p => ((p.size/p.theme.grid) * 100) - ((8 * (p.theme.childCount - 1)) / p.theme.childCount)}%;
  margin-right: 8%;

  display: flex;
  flex-direction: column;
  justify-content: ${p => p.center ? "center" : "flex-start"};
  box-sizing: border-box;
`
