import { useEffect, useState } from "react";

const getIsFullscreen = () => document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen

export const useFullscreen = (element) => {
  const [isFullscreen, setFullscreen] = useState(getIsFullscreen());

  useEffect(() => {
    const fullscreenHandler = () => {
      setFullscreen(getIsFullscreen())
    }
    document.addEventListener("webkitfullscreenchange", fullscreenHandler);
    document.addEventListener("fullscreenchange", fullscreenHandler);

    return () => {
      document.removeEventListener("webkitfullscreenchange", fullscreenHandler);
      document.removeEventListener("fullscreenchange", fullscreenHandler);
    }
  }, [])

  return [
    isFullscreen,
    (value) => {
      if (typeof value === 'function') {
        value = value(isFullscreen)
      }

      let elem = element ?? document.body;
      if (value) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    }
  ]
}