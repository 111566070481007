import { round } from "./round";
import { formatNumber } from "./formatNumber";
import formatSeconds from "./formatSeconds";

const formatMilliseconds = (ms) => {
  if (ms >= 1000) {
    return formatSeconds(round(ms / 1000))
  }
  return formatNumber(round(ms)) + "ms";
};

export default formatMilliseconds;