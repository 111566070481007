import styled from "styled-components/macro";

export const Copy = styled('div')`
  color: #333333;
  font-family: "Work Sans";
  font-weight: 300;
  font-size: 16px;
  
  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    /* margin-bottom: 15px; */
  }
  h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
  p, ol {
    margin: 0;
    margin-bottom: 15px;
  }
  a {
    color: #333333;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Small = styled('p')`
  font-size: 14px;
`