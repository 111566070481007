import React from "react";
import styled from "styled-components/macro";
import { MOBILE_BREAKPOINT } from "../../../shared/consts/css-snippets";
import LogoImage from "./images/logo.png";
import { Space } from "./index";

const StyledFooter = styled("div")`
  width: 100%;
  height: 63px;
  background-color: #333333;
  flex: 0 0 63px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: #cce3ba;
  font-family: "Work Sans";
  font-size: 12px;
  font-weight: 300;

  img {
    margin-right: 17px;
  }
`;

const StyledA = styled.a`
  :link,
  :visited,
  :active {
    color: #cce3ba;
    text-decoration: ${p => p.noUnderline && `none`};
  }

  margin-right: ${p => p.marginRight || null};
  @media (min-width: ${MOBILE_BREAKPOINT}) {
    margin-right: 80px;
  }
`;


export const Footer = () => (
  <StyledFooter>
    <img src={LogoImage} />
    <span>
      <StyledA
        rel="noreffer noopener"
        target="_blank"
        href="https://www.nemosciencemuseum.nl/nl/"
      >
        Kijk ook op NEMO Science Museum
      </StyledA>
    </span>
    <Space />
    <span>
      <StyledA
        rel="noreffer noopener"
        marginRight="20px"
        noUnderline
        target="_blank"
        href="https://www.nemosciencemuseum.nl/nl/over-nemo/voorwaarden-en-privacy/privacyverklaring/"
      >
        Privacy
      </StyledA>
    </span>
  </StyledFooter>
);