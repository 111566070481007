import styled from "styled-components/macro"
import { opacity } from "../animations/opacity";
import { preventClickWhenHidden } from "../animations/PageAnimation";

export const duration = 150;

const Button = styled.button`
  height: 40px;
  border: 2px solid ${p => p.theme.color};
  color: ${p => p.theme.color};
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 31.33px;
  text-transform: uppercase;
  background: none;
  padding: 0 28px;
  outline: none;

  flex: 0 0 auto;

  margin-top: ${p => p.marginTop};

  &:active{
    outline: none;
    color: ${p => p.theme.backgroundColor};
    background-color: ${p => p.theme.color};
    & > svg{
       polyline, line{
        stroke: ${p => p.theme.backgroundColor};
      }
    }
  }

  ${p => opacity(p.status, duration)}
  animation-delay: 600ms;
  ${preventClickWhenHidden}

  :disabled {
    opacity: 0.4;
  }

`

export default Button