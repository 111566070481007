import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import { Content } from "../../components/Content";
import { Text } from "../../components/Text"
import { Title } from "../../components/Title"
import { Button } from "../../components/Button"


export const Space = styled("div")`
  min-height: 20px;
`

export const Home = () => {
  return (
    <Content>
      <Title>
        Ontdek met de Webapp Humania hoe uniek jouw leerlingen zijn!
      </Title>
      <Text>
        Tijdens het bezoek aan de tentoonstelling Humania in NEMO doen de
        leerlingen op hun eigen telefoon een aantal opdrachten. Om toegang te
        krijgen tot de webapp, maak je een account aan.
      </Text>

      <Link to={"/dashboard/login"} component={Button} primary>
        Inloggen op mijn account
      </Link>
      <Link to={`/dashboard/register`} component={Button}>
        ACCOUNT Registreren
      </Link>
      <Space/>
      <Text>Ben je een leerling?</Text>
      <Link to={`/overview`} component={Button}>
        TERUG
      </Link>
    </Content>
  )
}
