import React, { useState } from "react";
import useForm from "react-hook-form";
import { isEmail } from "validator";
import Input from "../components/Input";
import { Title } from "../components/Title";
import { Button } from "../components/Button";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Content } from "../components/Content";
import { Text } from "../components/Text";
import { Copy } from "../components/Copy";

export const ForgottenLink = styled('a')`
  color: #333333;
  cursor: pointer;
  font-family: "Work Sans";
  font-weight: 300;
  font-size: 16px;
`

const ErrorMessage = styled('div')`
  margin-top: -10px;
  margin-bottom: 10px;
  color: #e36a66;
`

const Space = styled("div")`
  min-height: 20px;
`


export const Login = ({ setUser, email, children }) => {
  const history = useHistory();
  const { register, handleSubmit, errors, watch, formState, setError } = useForm();
  const [error, setErrorMessage] = useState()
  const onSubmit = data => {
    fetch('/api/login', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(r => r.json())
      .then(({ success, user, message }) => {
        if (success) {
          setUser(user)
          history.replace('/dashboard')
        } else {
          setErrorMessage(message)
          setError("username")
          setError("password")
        }
      })
  };
  return (
    <Content>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Title>Inloggen</Title>
        <Text>
          {children || `Log in met je e-mailadres en wachtwoord op de Webapp Humania.`}
        </Text>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Input
          type="email"
          name="username"
          data-cy="email"
          isInvalid={!!errors.username}
          autoComplete="username"
          defaultValue={email || ''}
          label="E-mailadres"
          error={errors.username}
          ref={register({
            required: true,
            validate: { isEmail }
          })}
          placeholder="Vul je e-mailadres in"
        />
        <Input
          type="password"
          name="password"
          data-cy="password"
          isInvalid={!!errors.password}
          autoComplete="current-password"
          error={errors.password}
          ref={register({
            required: true,
            minLength: { value: 6, message: "Het wachtwoord moet minimaal 6 tekens lang zijn" }
          })}
          label="Wachtwoord"
          placeholder="Vul je wachtwoord in"
        />
        <Button as="button" primary>
          Inloggen
        </Button>
        <Link to="/dashboard/forgotten" component={ForgottenLink}>Wachtwoord vergeten</Link>
      </form>
      <Space />
      <Text>
      Nog geen account? 
      </Text>
      <Link to="/dashboard/register" component={Button}>ACCOUNT Registreren</Link>
      {/* <Copy>
        <h2>Geen toegang tot je e-mail?</h2>
        <Link to="/dashboard/oauth">Log in met je social media account</Link>
      </Copy> */}
    </Content>
  );
};
