import React, { useMemo } from "react";
import useFetch from "fetch-suspense";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  ResponsiveContainer
} from "recharts";

import { Content } from "../components/Content";
import { Box } from "../components/Box";
import { Circle } from "../components/Circle";
import { Bubble } from "../components/Bubble";
import { RatioContainer } from "../../shared/components/RatioContainer";
import { StyledPieChart } from "../components/StyledPieChart";

import { useStore } from "../store";
import { GREY, BLUE, RED } from "../consts/colors";

import IntroImage from '../../game/assignments/Assignment6/assets/route3.jpg'
import noseImg from "../../game/assignments/Assignment6/assets/neus.jpg";
import crosseyeImg from "../../game/assignments/Assignment6/assets/scheeloog.jpg";
import tongueImg from "../../game/assignments/Assignment6/assets/tongklaver.jpg";
import tongueRollImg from "../../game/assignments/Assignment6/assets/tongrol.jpg";
import eyebrowImg from "../../game/assignments/Assignment6/assets/wenkbrauw.jpg";
import { SVGImage } from "../components/SVGImage";
import { InfoBox } from "../components/InfoBox";
import { NoData } from "../components/NoData";

const tongueRoll = "tongueRoll";
const tongue = "tongue";
const nose = "nose";
const crosseye = "crosseye";
const eyebrow = "eyebrow";

const Items = {
  [tongueRoll]: {
    img: tongueRollImg,
    label: "Tong rolletje"
  },
  [tongue]: {
    img: tongueImg,
    label: "Tong klavertje"
  },
  [nose]: {
    img: noseImg,
    label: "Neus likken"
  },
  [crosseye]: {
    img: crosseyeImg,
    label: "Scheel kijken"
  },
  [eyebrow]: {
    img: eyebrowImg,
    label: "Wenkbrauwen"
  }
};

const Item = props => {
  const x = props.x;
  const y = props.y;
  const { value, coordinate } = props.payload;
  const item = Items[value];

  return <SVGImage id={value} x={x} y={y} radius={40} img={item.img} />;
};

const TITLE = "Lichamelijk (on)mogelijk"

export const Assignment6_2 = () => {
  const { user } = useStore();
  const stats = useFetch(`/api/stats/6/${user.code}`);
  const data = useMemo(
    () => [
      { id: tongueRoll, value: stats.school?.can_twist_tongue, offset: 300 },
      { id: tongue, value: stats.school?.can_clover_tongue },
      { id: nose, value: stats.school?.can_lick_nose },
      { id: crosseye, value: stats.school?.can_cross_eye },
      { id: eyebrow, value: stats.school?.can_move_eyebrows }
    ],
    [stats]
  );

  if (!stats.school) return <NoData title={TITLE} />


  return (
    <Content grid={6} title={TITLE}>
      <Box size={4} center>
        <RatioContainer ratio={0.8}>
          <ResponsiveContainer>
            <RadarChart width={400} height={400} data={data} margin={{top: 150}}>
              <PolarGrid stroke={BLUE[0]} strokeOpacity={0.5} />
              <PolarAngleAxis tickSize={50} tick={<Item />} dataKey="id" type="category" />
              <Radar
                dataKey="value"
                stroke={BLUE[1]}
                fill={BLUE[0]}
                fillOpacity={0.6}
              />
            </RadarChart>
          </ResponsiveContainer>
        </RatioContainer>
      </Box>
      <InfoBox size={2}>
        <Circle src={IntroImage} />
        <h3>Unieke bewegingen</h3>
        <p>
          Dit is een overzicht van welke uniekebewegingen deze klas wel of niet
          kan maken. Welke bewegingen zou je met oefenen kunnen leren?
        </p>
      </InfoBox>
    </Content>
  );
};
