import styled from 'styled-components/macro';

const ColumnDiv = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  text-align: center;
`;

export default ColumnDiv;