import React from "react";
import { ThemeProvider } from "styled-components/macro";

import {
  Switch,
  Route,
} from "react-router-dom";
import { Login } from "../dashboard/pages/Login";
import Container from "../game/components/Container";
import { Home } from "./pages/Home";
import Helmet from "react-helmet";



export const Digiboard = ({ user }) => {
  return (
    <ThemeProvider
      theme={{ backgroundColor: "white", pageStatus: "entered" }}
    >
      <Container shouldCenter>
        {user && <Helmet>
          <link rel="prefetch" href={`/api/stats/3/${user.code}`} />
          <link rel="prefetch" href={`/api/stats/6/${user.code}`} />
          <link rel="prefetch" href={`/api/stats/7/${user.code}`} />
          <link rel="prefetch" href={`/api/stats/8/${user.code}`} />
          <link rel="prefetch" href={`/api/stats/10/${user.code}`} />
          <link rel="prefetch" href={`/api/stats/11/${user.code}`} />
          <link rel="prefetch" href={`/api/stats/13/${user.code}`} />
        </Helmet>}
        <Switch>
          {!user ? (
            <Switch>
              <Route path={"/digiboard"}>
                <Login />
              </Route>
            </Switch>
          ) : (
              <Switch>
                <Route exact path={"/digiboard"}>
                  <Home user={user} />
                </Route>
              </Switch>
            )}
        </Switch>
      </Container>
    </ThemeProvider>
  );
};
