import React, { useMemo } from "react";
import useFetch from "fetch-suspense";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart,
  Area
} from "recharts";

import { Content } from "../components/Content";
import { Box } from "../components/Box";
import { Circle } from "../components/Circle";
import { Bubble } from "../components/Bubble";

import { useStore } from "../store";

import IntroImage from '../../game/assignments/Assignment10/route3.png'
import { RatioContainer } from "../../shared/components/RatioContainer";
import { GREY, BLUE } from "../consts/colors";
import { InfoBox } from "../components/InfoBox";
import { NoData } from "../components/NoData";

export const mapResults = (items) => {
  if (!items) return
  return [...new Array(100)].map((_, index) => {
    return (
      items.find(item => item.pressed === index + 1) ?? {
        pressed: index + 1,
        amount: 0
      }
    );
  });
};

const TITLE = "Doe de test"

export const Assignment10 = () => {
  const { user } = useStore();
  const stats = useFetch(`/api/stats/10/${user.code}`);
  const items = useMemo(() => {
    return mapResults(stats.school?.items);
  }, [stats.school?.items]);

  if (!stats.school) return <NoData title={TITLE} />
  return (
    <Content grid={6} title={TITLE}>
      <Box size={4} center>
        <RatioContainer ratio={2/3}>
          <ResponsiveContainer>
            <AreaChart
              width={400}
              height={400}
              data={items}
              margin={{ top: 65, right: 30, bottom: 50 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis allowDecimals={false} label={{value: "Aantal mensen", position: "insideTopLeft", dy: -50, fill: GREY}} stroke={GREY} />
              <XAxis
                stroke={GREY}
                label={{value: "Keer gedrukt", position: "bottom", dy: 5, fill: GREY}}
                allowDecimals={false}
                dataKey="pressed"
                type="number"
                domain={[0, 100]}
              />
              <Area dataKey="amount" stroke={BLUE[0]} type="monotone" />
            </AreaChart>
          </ResponsiveContainer>
        </RatioContainer>
      </Box>
      <InfoBox size={2}>
        <Circle src={IntroImage} />
        <h3>Volg de instructies</h3>
        <p>
          Hier staat de statistieken van de klas van de volgzaamheids opdracht.
          Hoe volgzaam is jullie klas?
        </p>
      </InfoBox>
    </Content>
  );
};
