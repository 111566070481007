import React, { createContext, useState, useContext, useEffect } from "react";
import createUUID from 'uuid/v4'

const ITEM_KEY = "progress"

const emptyArray = []

let uuid = localStorage.getItem("uuid");
if (!uuid) {
  uuid = createUUID()
  localStorage.setItem("uuid", uuid);
}

export const Context = createContext({
  completed: emptyArray
})

export const StoreProvider = (props) => {
  const [completed, setCompleted] = useState(emptyArray)
  const [isModalVisible, setModalVisible] = useState(false)
  
  useEffect(() => {
    const data = localStorage.getItem(ITEM_KEY)
    if (data) {
      setCompleted(JSON.parse(data))
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(ITEM_KEY, JSON.stringify(completed))
  }, [completed])

  return (
    <Context.Provider value={{
      completed,
      setCompleted,
      uuid,
      isModalVisible,
      setModalVisible,
      ...props.login
    }} {...props} />
  )
}

export const useIsCompleted = (id, shouldBeLast) => {
  const { completed } = useContext(Context)
  if (shouldBeLast) {
    return completed[completed.length] === id
  }
  return completed.includes(id)
}

export const useStore = () => {
  return useContext(Context)
}