import React, { createContext, useRef, useEffect, useCallback, useContext, useState } from 'react'
import styled from 'styled-components/macro';
import { pageAnimation } from '../pages/Overview/animations';

const Context = createContext()

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${p => p.shouldCenter && `center`};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: ${p => p.backgroundColor || (p.theme.pageStatus === "entered" && p.theme.backgroundColor)};
  ${pageAnimation}
`;

export default (props) => {
  const ref = useRef()
  const handler = (ev) => {
    if (ref.current) {
      ref.current(ev)
    }
  }
  return (
    <Context.Provider value={{
      setHandler: (handler) => {
        ref.current = handler;
      },
      unsetHandler: () => ref.current = null,
    }}>
      <Container {...props} onScroll={handler} />
    </Context.Provider>
  );
};

export const useScrollOffset = () => {
  const [scrollTop, setScrollTop] = useState(0)
  const handler = useCallback((ev) => {
    setScrollTop(ev.target.scrollTop)
  }, [])
  const { setHandler, unsetHandler } = useContext(Context)

  useEffect(() => {
    setHandler(handler)
    return () => {
      unsetHandler(handler)
    }
  }, [])

  return scrollTop
}