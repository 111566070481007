import React from "react";
import styled from "styled-components/macro";

import { Space } from "../../dashboard/components/Space";

import { useStore } from "../store";

import Expand from "../images/expand.svg";
import Contract from "../images/contract.svg";
import HomeImage from "../images/home.svg";
import BackImage from "../images/back.svg";
import ForwardImage from "../images/forward.svg";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  padding: 40px;
  padding-top: 0;
  align-items: center;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 2px solid #333333;
  border-radius: 50%;
  background-color: transparent;

  &:disabled {
    opacity: 0.3;
  }
`;

const Page = styled.div`
  color: #333333;
  font-family: "Work Sans";
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  margin: 16px;
  min-width: 55px;
  text-align: center;
`;

const Img = styled("img")`
  width: 20px;
  height: 20px;
`;

export const Navigation = () => {
  const {
    setFullscreen,
    isFullscreen,
    pages,
    pageIndex,
    setPageIndex
  } = useStore();
  return (
    <Container>
      <Link to="/dashboard" component={Button} as="a">
        <Img src={HomeImage} />
      </Link>
      <Space />
      <Button
        disabled={pageIndex === 0}
        onClick={() => setPageIndex(p => p - 1)}
      >
        <Img src={BackImage} />
      </Button>
      <Page>{pageIndex + 1}/{pages.length}</Page>
      <Button
        disabled={pageIndex === pages.length - 1}
        onClick={() => setPageIndex(p => p + 1)}
      >
        <Img src={ForwardImage} />
      </Button>
      <Space />
      <Button
        onClick={() => {
          setFullscreen(f => !f);
        }}
      >
        <Img src={isFullscreen ? Contract : Expand} />
      </Button>
    </Container>
  );
};
