import React from 'react'
import styled, { css, ThemeProvider } from 'styled-components/macro'
import { Box, Margin } from './Box';
import { Label, Circle } from './Bubble';
import { GREY } from '../consts/colors';

const media = `(min-height: 1000px) and (min-width: 1330px)`

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
`

const Slide = styled('div')`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 40px; */
  max-width: 140vh;
`

const StyledContent = styled("div")`
  box-sizing: border-box;
  /* position: relative; */
  display: flex;
  align-items: stretch;
  width: 100%;
  /* flex: 1; */

  padding: 40px;

  ${p =>
    p.shouldCenter &&
    css`
      align-items: center;
      justify-content: center;
    `}
  ${p =>
    p.shouldScroll &&
    css`
      overflow: auto;
    `}

  ${Box}:last-child {
    margin-right: 0;
  }

  -webkit-overflow-scrolling: touch;
  /* z-index: 2; */
  
  color: #333333;
  font-family: "Work Sans";
  font-size: 1.5vw;
  line-height: 1.1;

  h1 {
    font-size: 64px;
    font-weight: 400;
    margin: 0;

    @media (min-height: 1000px) and (min-width: 1330px) {
      font-size: 70px;
    }
  }
  
  h3 {
    font-weight: 400;
    margin: 0;
    font-size: 26px;
    margin-bottom: 5px;

    @media (min-height: 1000px) and (min-width: 1330px) {
      font-size: 30px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.4;

    @media (min-height: 1000px) and (min-width: 1330px) {
      font-size: 22px;
    }
  }

  ${Label} {
    font-size: 18px;
    @media (min-height: 1000px) and (min-width: 1330px) {
      font-size: 22px;
    }
  }

  ${Circle} {
    font-size: 26px;
    @media (min-height: 1000px) and (min-width: 1330px) {
      font-size: 30px;
    }
  }
`;

const Title = styled('h1')`
  color: ${GREY};
  font-family: "Work Sans";
  font-size: 48px;
  font-weight: 300;
  line-height: 62px;
  padding-top: 40px;
  padding-left: 40px;
  margin: 0;
  align-self: flex-start;
  line-height: 1.1;

  @media (min-height: 1000px) and (min-width: 1330px) {
    font-size: 54px;
  }
`

export const Subtitle = styled('h2')`
  color: ${GREY};
  font-family: "Work Sans";
  font-size: 26px;
  font-weight: 400;
  margin: 0;
  line-height: 1.1;
  text-align: center;

  @media (min-height: 1000px ) and (min-width: 1330px) {
    font-size: 30px;
  }
`

const Space = styled('div')`
  flex: 1;
`

export const Content = ({grid, title, subtitle, ...props}) => {
  return (
    <ThemeProvider theme={{grid, childCount: props.children.length}}>
      <Container>
        <Title>{title}</Title>
        {/* <Subtitle>{subtitle}</Subtitle> */}
        <Space />
        <Slide>
          <StyledContent {...props} />
        </Slide>
        <Space />
      </Container>
    </ThemeProvider>
  )
}