import { css, keyframes } from "styled-components/macro";
import { MaterialStandard } from "../../shared/consts/ease";

export const duration = 300;

const opacityIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const opacityOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const opacity = (status, d = duration, delay = 0) => css`
  ${status === "entering"
    ? css`
        opacity: 0;
        animation: ${opacityIn} ${d}ms ${MaterialStandard} forwards;
        animation-delay: ${p => delay + (p.theme.delay || 0) }ms;
      `
    : status === "exiting"
    ? css`
        opacity: 1;
        animation: ${opacityOut} 250ms ${MaterialStandard} forwards;
      `
    : undefined}
`;
