import React, { useContext } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Transition } from "react-transition-group";
import Footer from "./Footer";
import StyledNextButton, { duration } from './Button'
import { Context } from "../../shared/store/store";

const NextButton = ({ isDiscrete, shouldShow, ...props }) => {
  return (
    <Transition
      in={shouldShow || shouldShow === undefined}
      timeout={duration}
      unmountOnExit
    >
      {status => {
        return (
          <Footer status={status}>
            <StyledNextButton
              status={status}
              isDiscrete={isDiscrete}
              {...props}
            />
          </Footer>
        );
      }}
    </Transition>
  );
};

const StyledLink = styled(Link)`
  &:focus, :active{
    outline: none;
    background: none;
  }
`;

export default ({ id, onCompleted, isLast, setStep, ...props }) => {
  const { setCompleted, completed, uuid, code } = useContext(Context)
  if (isLast) {
    return (
      <StyledLink to="/overview" onClick={() => {
        if (props.shouldShow === false) {
          return
        }
        if (onCompleted) { //!completed[id] && 
          onCompleted(uuid, code)
        }
        if (id) {
          setCompleted(c => ([...c, id]))
        }
      }}>
        <NextButton {...props} />
      </StyledLink>
    );
  }
  return (
    <NextButton onClick={() => setStep(s => s + 1)} {...props}></NextButton>
  );
};
