import React from "react";
import { Route as ReactRoute, useLocation } from "react-router-dom";
import { opacity } from "../../animations/opacity";
import { Transition } from "react-transition-group";
import { keyframes, css, ThemeProvider } from "styled-components/macro";
import { MaterialStandard, MaterialDecelerate } from "../../../shared/consts/ease";


const opacityOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const transformIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0, 0);
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
`

const transformOut = keyframes`
  from {
    transform: scale(1, 1);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(0, 0);
  }
`

const Context = React.createContext()

export const Provider = (props) => {
  const [value, setValue] = React.useState()
  return (
    <Context.Provider value={{value, setValue}} {...props}/>
  )
}

export const pageAnimation = p => css`
  ${(p.theme.pageStatus === "entering") && css`
    opacity: 0;
    animation: ${keyframes`to { opacity: 1; }`} 1ms ${MaterialStandard} forwards;
    animation-delay: 299ms;
  `}
  ${(p.theme.pageStatus === "exiting") && css`
    opacity: 1;
    animation: ${keyframes`to { opacity: 0; }`} 300ms ${MaterialStandard} forwards;
  `}
`;

export const transform = (status, delay) => css`
  
  ${(status === "entering" || status === "entered") ? css`
    transform: scale(0, 0);
    animation: ${transformIn} ${150}ms ${MaterialDecelerate} forwards;
  ` : css`
    animation: ${transformOut} ${150}ms ${MaterialStandard} forwards;
  `}
  animation-delay: ${delay}ms;
`

export const Route = ({ children, ...props }) => {
  const location = useLocation();
  const {value, setValue} = React.useContext(Context)

  return (
    <ReactRoute {...props}>
      {({ match }) => (
        <Transition
          in={match != null}
          timeout={location.pathname === "/overview" ? 300 : 1100}
          unmountOnExit
        >
          {status => {
            if (status === "exiting" && value !== props.path) {
              setValue(props.path)
            } else if (status === "exited") {
              setValue(null)
            }
            return (
              <ThemeProvider theme={{ pageStatus: status, delay: value === "/overview" ? 600 : 0 }}>
                {children}
              </ThemeProvider>
            )
          }}
        </Transition>
      )}
    </ReactRoute>
  );
};


