import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"

import ContentBase from "../../../game/components/Content"
import { Text } from "../../components/Text"
import { Title } from "../../components/Title"
import { Button } from "../../components/Button"
import {
  MOBILE_BREAKPOINT,
  DESKTOP_BREAKPOINT,
} from "../../../shared/consts/css-snippets"

import { Footer } from "./Footer"
import { Blocks } from "./Blocks"
import Container from "../../../game/components/Container"

const Content = styled(ContentBase)`
  padding: 0;
`

export const Wrapper = styled("div")`
  width: 100%;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    display: flex;
    flex: 0 0 calc(100% - 63px);
  }
`

const Contact = styled("div")`
  p {
    margin: 0;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    display: flex;

    p:first-child {
      margin-right: 80px;
    }
  }
`

const InfoA = styled.a`
  :link,
  :visited,
  :active {
    color: #333333;
  }
`

const Buttons = styled("div")`
  ${Button} {
    padding: 14px;
    text-align: left;
    line-height: 1.3333;
    font-size: 20px;
  }
  @media (min-width: 1250px) {
    display: flex;
    /* margin-right: 100px; */
    ${Button} {
      margin-right: 18px;
    }
  }
`

const StyledContent = styled(ContentBase)`
  padding: 30px;
  padding-top: 40px;
  align-items: initial;
  background-color: ${(p) => p.color || undefined};

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    padding: 80px;
    padding-top: 164px;
    padding-bottom: 64px;
  }

  h2 {
    margin: 0;
    margin-bottom: 15px;
    color: #333333;
    font-family: "Work Sans";
    font-size: 22px;
    font-weight: 300;
  }
`

const TextContent = styled("div")`
  max-width: 700px;
`

export const Space = styled("div")`
  flex: 1;
  min-height: 50px;
`

const Login = styled("a")`
  color: #333333;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-decoration: underline;

  position: absolute;
  right: 30px;
  top: 20px;
`

const P = styled.p`
  /* Style for "Meer infor" */
  color: #333333;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
`

export const Start = () => {
  return (
    <Container>
      <Content shouldScroll>
        <Wrapper>
          <StyledContent>
            <TextContent>
              <Title>
                Ontdek met de Webapp Humania hoe uniek jouw leerlingen zijn!
              </Title>
              <Text>
                Tijdens het bezoek aan de tentoonstelling Humania in NEMO doen
                de leerlingen op hun eigen telefoon een aantal opdrachten. Om
                toegang te krijgen tot de webapp, maak je een account aan.
              </Text>

              <Buttons>
                <Link to={`/overview`} component={Button} primary>
                  IK BEN LEERLING BIJ NEMO EN WIL INLOGGEN MET DE KLASSENCODE
                </Link>
                <Link to={"/dashboard"} component={Button}>
                  IK BEN LEERKRACHT EN IK WIL INLOGGEN OF REGISTREREN
                </Link>
              </Buttons>

              <Space />

              <h2>Nog geen reservering voor een schoolbezoek bij NEMO?</h2>
              <Contact>
                <P>
                  <b>Meer informatie</b>
                  <br />
                  <span>
                    <InfoA
                      rel="noopener noreffer"
                      target="_blank"
                      href="https://www.nemosciencemuseum.nl/nl/onderwijs/primair-onderwijs/schoolbezoek-nemo/"
                    >
                      Schoolbezoek NEMO
                    </InfoA>
                  </span>
                  <br />
                  <span>
                    <InfoA
                      rel="noopener noreffer"
                      target="_blank"
                      href="https://www.nemosciencemuseum.nl/nl/onderwijs/voortgezet-onderwijs/schoolbezoek-nemo/workshop/secondary/webapp-humania/"
                    >
                      Webapp Humania
                    </InfoA>
                  </span>
                </P>

                <P>
                  <b>Reserveringen</b> <br />
                  020-531 31 18
                  <br />
                  reserveringen@e-nemo.nl
                </P>
              </Contact>
            </TextContent>
          </StyledContent>
          <Blocks />
        </Wrapper>
        <Footer />
      </Content>
    </Container>
  )
}
