import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${p => p.shouldCenter && `center`};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cce3ba;
  background-image: linear-gradient(-33deg, #cce3ba 0%, #bfddb8 100%);
`;
