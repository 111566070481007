import styled, { withTheme } from "styled-components/macro";
import reveal, { Wrapper } from "../animations/reveal";
import React from "react";
import { opacity } from "../animations/opacity";

export const Title = styled.h2`
  color: ${p => p.theme.color};
  font-family: "Work Sans";
  font-weight: 300;
  text-align: center;
  margin: 0;
  margin-right: 13px;
  margin-left: 13px;
  margin-top: ${p => p.marginTop || `0px`};
  margin-bottom: ${p => p.marginBottom || "0px"};
  ${p => reveal(p.theme.status)}
  ${p => opacity(p.theme.pageStatus, 150, p.delay)}
  font-size: 1.8em;
  z-index: 2;
  /* font-size: 25px; */
`;

export default withTheme(props => (
  <Wrapper status={props.theme.status} className={props.className}>
    <Title {...props} />
  </Wrapper>
));
