import useFetch from "fetch-suspense"
import React, { lazy, useState } from "react"
import {
  BrowserRouter as Router, useHistory, useRouteMatch
} from "react-router-dom"
import { createGlobalStyle } from "styled-components/macro"
import { Digiboard } from "./digiboard"
import { Game } from "./game"
import { Provider } from "./game/pages/Overview/animations"
import { useLogin } from "./shared/store/login"
import { StoreProvider } from "./shared/store/store"
import { isMobile } from "./shared/utils/isMobile"
import { Start } from "./dashboard/pages/Home/Start"




// 1366 x 728

const GlobalStyles = createGlobalStyle`
  body, p {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    touch-action: manipulation;
    button {
      touch-action: manipulation;
    }
    @media (max-width: 374px) {
      font-size: 14px;
    }
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
`;


const Dashboard = lazy(() => import("./dashboard"));


export const validate = code =>
  fetch(`/api/validate/${code}`)
    .then(r => r.json())
    .then(({ valid }) => valid);

function App() {
  let history = useHistory();
  let codeMatch = useRouteMatch("/code/:code");
  let dashboard = useRouteMatch("/dashboard");
  let digiboard = useRouteMatch("/digiboard");
  let home = useRouteMatch({ path: "/", exact: true });

  const { user: userIn, baseURL } = useFetch("/api/user", {
    credentials: "include"
  });
  const [user, setUser] = useState(userIn);

  const login = useLogin({
    codeMatch,
    user,
    baseURL,
    history,
    localStorage,
    validate
  });
  
  return (
    <StoreProvider login={login}>
      <GlobalStyles />
      <Provider>

        {!home && !dashboard && !digiboard && <Game user={user} baseURL={baseURL} />}
        {!!home && <Start user={user} baseURL={baseURL} />}
        {!!dashboard && <Dashboard setUser={setUser} user={user} baseURL={baseURL} />}
        {!!digiboard && <Digiboard setUser={setUser} user={user} baseURL={baseURL} />}

      </Provider>
    </StoreProvider>
  );
}

export default () => {
  return (
    <Router>
      <React.Suspense fallback={<div />}>
        <App />
      </React.Suspense>
    </Router>
  );
};
