import bugsnag from "@bugsnag/js"
import bugsnagReact from "@bugsnag/plugin-react"
import React from "react"
import { createRoot } from 'react-dom/client'
import App from "./App"
import * as serviceWorker from "./serviceWorker"

export const bugsnagClient = bugsnag({
  apiKey: "1c26e929f12d7bf86affadb0bab22e87",
  notifyReleaseStages: ["production", "staging"]
});
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin("react");

const root = createRoot(document.getElementById("root"))

root.render(<ErrorBoundary>
    <App />
  </ErrorBoundary>,
);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    root.render(
      <ErrorBoundary>
        <NextApp />
      </ErrorBoundary>,
    );
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
