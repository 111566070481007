import styled, { css, keyframes } from 'styled-components/macro'
import { MaterialStandard } from '../../shared/consts/ease';

const duration = 150

const keyframesIn = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
`;

const keyframesOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100px);
  }
`;

const reveal = (status, delay = 0) => css`
  ${(status === "entering")
    ? css`
        /* opacity: 0; */
        transform: translateY(-100px);
        animation: ${keyframesIn} ${duration}ms ${MaterialStandard} forwards;
        animation-delay: ${p => delay + (p.theme.delay || 0) }ms;
      `
    : (status === "exiting") ? css`
        animation: ${keyframesOut} ${duration}ms ${MaterialStandard} forwards;
        /* animation-delay: ${p => delay + (p.theme.delay || 0)}ms; */
      ` : undefined}
`;

export default reveal


export const Wrapper = styled.div`${p => ["entering", "exiting"].includes(p.status) && css`overflow: hidden; flex: 0 0 auto;`}`