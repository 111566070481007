import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const handler = (e) => {
  e.returnValue = "Weet je het zeker?"
}

const useBeforeUnload = () => {
  const page = useLocation();

  useEffect(() => {
    if (page.pathname !== "/overview" && page.pathname !== "/") {
      window.addEventListener('beforeunload', handler) 
      return () => {
        window.removeEventListener('beforeunload', handler) 
      }
    }
  }, [page])

}

export default useBeforeUnload