import { Box } from "./Box";
import styled from "styled-components";


export const InfoBox = styled(Box)`
  text-align: center;
`

InfoBox.defaultProps = {
  center: true
}