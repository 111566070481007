
import React from 'react'
import styled from 'styled-components'

import { Content } from "../components/Content"


const StyledContent = styled(Content)`
  
  flex-direction: column;
  text-align: center;
  width: 100%;

  h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: 1.4;
    margin: 0;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 36px;
    font-weight: 300;
    line-height: 1.4;
    margin: 0;
  }
  
`

export const End = () => {
  return (
    <StyledContent grid={1}>
      <h1>Zijn jullie uniek? Of eigenlijk best wel hetzelfde?</h1>
      <h2>Bespreek met elkaar wat jullie uniek maakt<br/> en waar jullie hetzelfde in zijn.</h2>
    </StyledContent>
  )
}