import styled from "styled-components/macro";
import { MOBILE_BREAKPOINT } from "../../shared/consts/css-snippets";


export const Title = styled("h1")`
  color: ${p => p.color || `#333333`};
  font-family: "Work Sans";
  font-size: 28px;
  font-weight: 400;
  /* line-height: 1.2; */
  margin-left: 0;
  margin-right: 0;
  margin-top: ${p => p.marginTop || `12px`};
  margin-bottom:${p => p.marginBottom || `20px`};
   
  @media (min-width: ${MOBILE_BREAKPOINT}) {
    font-size: 42px;
  }
`;
