import React from "react"
import styled, { ThemeProvider, css } from "styled-components/macro"
import { TransitionGroup, Transition } from "react-transition-group"
import { MaterialDecelerate } from "../../shared/consts/ease"
import { opacity, duration } from "./opacity"

export const preventClickWhenHidden = p => (p.status && p.status !== "entered") && css`pointer-events: none;`

const Container = styled("div")`
  flex: 1;
  position: relative;
`;

const Content = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  ${p => opacity(p.status)}
`;

export default ({ children, pageKey }) => {
  return (
    <Container>
      <TransitionGroup>
        <Transition timeout={duration} key={pageKey}>
          {status => (
            <ThemeProvider theme={{ status }}>
              <Content status={status}>{children}</Content>
            </ThemeProvider>
          )}
        </Transition>
      </TransitionGroup>
    </Container>
  )
}
