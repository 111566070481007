import React from "react";
import styled, { css, keyframes } from "styled-components/macro";
import reveal from "../animations/reveal";
import { MaterialStandard } from "../../shared/consts/ease";


const FadeInFrames = keyframes`
  70% {
    opacity: 1;
  }
  100% {
    transform: scale(1, 1) translate(-50%, -50%);
  }
`;


const BorderFrames = keyframes`
  0% {
    border-width: 1px;
  }
  80% {
    transform: scale(1.4, 1.4) translate(-50%, -50%);
    border-width: 6px;
    padding: 40px;
  }
  100% {
    transform: scale(1, 1) translate(-50%, -50%);
    border-width: 2px;
    padding: 3px;
  }
`;

const ImgFrames = keyframes`
  60% {
    transform: scale(1.3, 1.3) translate(-50%, -50%);
  }
  100% {
    transform: scale(1, 1) translate(-50%, -50%);
  }
`;


const Img = styled.img`
  box-sizing: border-box;
  border-radius: 50%;
  width: 289px;
  height: 289px;

  border-style: solid;
  border-width: 3px;
  border-color: ${p => p.theme.backgroundColor};

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${p => {
    const status = p.theme.pageStatus
    if (status === 'entering') {
      return css`
        transform: scale(0, 0) translate(-50%, -50%);
        transform-origin: 0 0;
        animation: ${ImgFrames} 550ms ${MaterialStandard} forwards;
        animation-delay: 300ms;
      `;
    }
  }}
`;

const Border = styled.div`
  box-sizing: border-box;
  border: ${p => p.theme.color} solid 2px;
  border-radius: 50%;
  width: 295px;
  height: 295px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${p => {
    const status = p.theme.pageStatus
    if (status === 'entering') {
      return css`
        padding: 0;
        transform: scale(0, 0) translate(-50%, -50%);
        transform-origin: 0 0;
        animation: ${BorderFrames} 600ms ${MaterialStandard} forwards;
        animation-delay: 300ms;
      `;
    }
  }}
`

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 100vh;
  height: 300px;
  /* overflow: hidden; */
  margin: 0;
  margin-bottom: ${p => p.marginBottom || "20px"};
  user-select: none;

  position: relative;
`;

export default props => (
  <Wrapper delay={props.delay}>
    <Border {...props} />
    <Img {...props} />
  </Wrapper>
);
