import { useEffect, useState } from "react";

export const useLogin = ({ codeMatch, user, baseURL, history, localStorage, validate }) => {
  
  useEffect(() => {
    if (codeMatch) {
      //Save:
      localStorage.setItem("code", codeMatch.params.code);
      // Redirect
      history.replace("/");
    }
  }, [codeMatch && codeMatch.params.code]);

  const code = localStorage.getItem("code") || (user && user.code);
  let [isValid, setValid] = useState(false);

  isValid = isValid || !!user

  useEffect(() => {
    if (code && !isValid) {
      validate(code)
        .then(valid => {
          if (!valid) {
            localStorage.removeItem("code")
            history.replace("/expired");
          }
          setValid(valid)
        })
    }
  }, [code, isValid]);

  return {
    isValid,
    setValid,
    code
  }

}