// const OVERVIEW_THEME = {};

export const THEMES = {
  "overview": {

  },
  1: {
    color: "#333333",
    backgroundColor: "#bfddb8"
  },
  2: {
    color: "#333333",
    backgroundColor: "#cce3ba"
  },
  7: {
    color: "#ffffff",
    backgroundColor: "#e36a66"
  },
  3: {
    color: "#333333",
    backgroundColor: "#abd68e"
  },
  4: {
    color: "#333333",
    backgroundColor: "#daebcc"
  },
  5: {
    color: "#ffffff",
    backgroundColor: "#31335b"
  },
  6: {
    color: "#ffffff",
    backgroundColor: "#95a1ed"
  },
  8: {
    color: "#ffffff",
    // backgroundColor: "#b1baf2"
    backgroundColor: "#7d8ae3"
  },

  9: {
    color: "#ffffff",
    backgroundColor: "#8d97d6"
  },
  10: {
    color: "#ffffff",
    backgroundColor: "#808ad1",
  },
  11: {
    color: "#ffffff",
    backgroundColor: "#6677e3"
  },

  // 12: {
  //   color: "#ffffff",
  //   backgroundColor: "#54c748"
  // },
  13: {
    color: "#ffffff",
    backgroundColor: "#d18280"
  },
  "intro": {
    color: "#000000",
    backgroundColor: "#ffffff",
  }
};
