import React from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { CircleContent } from "../../components/Content";
import { Title } from "../../components/Title";
import Img from "../../components/Img";
import NextButton from "../../components/NextButton";
import Container from "../../components/Container";
import Header from "../../components/Header";

import BenIkZoalsImage from '../../../shared/images/benikzoals.jpg';
import HoeWordIkImage from '../../../shared/images/hoewordik.jpg';
import HoeZitIkImage from '../../../shared/images/hoezitik.jpg';
import IkWasImage from '../../../shared/images/ikwas.jpg'
import IkBenImage from '../../../shared/images/ikben.jpg'
import IkWordImage from '../../../shared/images/ikword.jpg'
import KijkNaarImage from '../../../shared/images/kijknaarjezelf.jpg';
import KleurGeheugenImage from '../../../shared/images/kleurgeheugen.jpg';
import PrikkelJeBreinImage from '../../../shared/images/prikkeljebrein.jpg';
import TypischTomImage from '../../../shared/images/typischtom.jpg';
import VerwantAanImage from '../../../shared/images/verwantaan.jpg';
import VooroudersImage from '../../../shared/images/voorouders.jpg';
import WaarKomIkVandaanImage from '../../../shared/images/waarkomikvandaan.jpg';
import WatJijWiltImage from '../../../shared/images/watjijwilt.jpg';
import WieWilIkZijnImage from '../../../shared/images/wiewilikzijn.jpg';


export {
  BenIkZoalsImage,
  HoeWordIkImage,
  HoeZitIkImage,
  IkWasImage,
  IkBenImage,
  IkWordImage,
  KijkNaarImage,
  KleurGeheugenImage,
  PrikkelJeBreinImage,
  TypischTomImage,
  VerwantAanImage,
  VooroudersImage,
  WaarKomIkVandaanImage,
  WatJijWiltImage,
  WieWilIkZijnImage
}

const Base = ({ img, title, header }) => {
  return (
    <Container>
      <Header step={0} />
      <CircleContent header={header}>
        <Img src={img} rel="preload" />
      </CircleContent>
      <NextButton disabled>Loading...</NextButton>
    </Container>
  );
};

export const IkWas = () => (
  <Base
    img={IkWasImage}
    header={
      <Title>
        Begin bij
        <br />
        IK WAS
      </Title>
    }
  />
);

export const IkBen = () => (
  <Base
    img={IkBenImage}
    header={
      <Title>
        Begin bij
        <br />
        IK BEN
      </Title>
    }
  />
);

export const IkWord = () => (
  <Base
    img={IkWordImage}
    header={
      <Title>
        Begin bij
        <br />
        IK WORD
      </Title>
    }
  />
);
