import styled from 'styled-components/macro';
// Better responsive container than ResponsiveContainer
export const StyledPieChart = styled('div')`

  width: 100%;
  height: 100%;

  div, svg {
    flex: 1;
    width: 100% !important;
    height: 100% !important;
  }
`;
