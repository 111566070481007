import React from "react";
import styled from "styled-components/macro";
import { THEMES } from "../../../shared/consts/themes";
import { DESKTOP_BREAKPOINT } from "../../../shared/consts/css-snippets";


import joystickImg from "./images/joystick.png";
import plantImg from "./images/plant.png";
import brainImg from "./images/brain.png";
import fistImg from "./images/fist.png";
import nametagImg from "./images/nametag.png";
import wheelImg from "./images/wheel.png";

const TinyImg = styled.img`
  min-width: 70px;
  max-width: 20vw;
`;

const StyledBlocks = styled("div")`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    width: calc(${(100 / 3) * 2}vh - 42px);
  }
`;

const Block = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.color};
  width: ${100 / 3}%;
  height: ${100 / 3}vw;

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    width: calc(${100 / 3}vh - 21px);
    height: ${100 / 3}%;
  }
`;

export const Blocks = () => (
  <StyledBlocks>
    <Block color={THEMES[1].backgroundColor}>
      <TinyImg src={plantImg} alt="" />
    </Block>
    <Block color={THEMES[7].backgroundColor}>
      <TinyImg src={joystickImg} alt="" />
    </Block>
    <Block color={THEMES[3].backgroundColor}>
      <TinyImg src={nametagImg} alt="" />
    </Block>
    <Block color={THEMES[13].backgroundColor}>
      <TinyImg src={wheelImg} alt="" />
    </Block>
    <Block color={THEMES[6].backgroundColor}>
      <TinyImg src={fistImg} alt="" />
    </Block>
    <Block color={THEMES[8].backgroundColor}>
      <TinyImg src={brainImg} alt="" />
    </Block>
  </StyledBlocks>
);
