import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import QrImg from './qr.svg';

const QrIcon = styled('a')`
  position: absolute;
  left: 20px;
  top: 20px;

  img {
    width: 20px;
    height: 21px;
  }
`;

export default () => <Link to="/code" component={QrIcon}><img src={QrImg} /></Link>;