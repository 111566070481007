import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import styled, { css, withTheme, keyframes } from "styled-components/macro";

import { transform } from "./animations";
import { MaterialStandard } from "../../../shared/consts/ease";
import {  useIsCompleted } from "../../../shared/store/store";

import check from './assets/check@2x.png'

const FadeOut = keyframes`
  100% {
    opacity: 0;
  }
`;

const zoom = keyframes`
  to {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 0px;
  }
`;

const ImgFramesIn = keyframes`
  from {
    transform: scale(0, 0);
  }
  80% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1)
  }
`;

const ImgFramesOut = keyframes`
  80% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(0, 0)
  }
`;

const Check = styled.img`
  margin: auto;
  position: absolute;
  height: 7.5vw;
  width: 10vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Img = styled.img`
  
`

const StyledTile = styled(Link)`
  text-decoration: none;
  height: 30vw;
  box-sizing: border-box;
  position: relative;

  align-items: center;
  background-color:${p => p.disabled ? `grey` : p.backgroundColor || `#47a4b6`};
  border: none;
  /* border-radius: 5px; */
  color: white;
  display: flex;
  flex-grow: 1;
  font-size: 22px;
  justify-content: center;
  opacity: ${p => (p.disabled ? 0.15 : 1)};
  text-decoration: none;
  padding: 25px 0;

  ${p => {
    if (p.isCompleted) {
      return css`
        background-color: #abd68e;
      `
    }
  }}

  ${Img} {
    width: auto;
    max-height: 100%;
    opacity: ${p => (p.disabled ? 0.15 : p.isCompleted ? 0.5 : 1)};
    transform-origin: center center;

    ${p => {
    const status = p.theme.pageStatus;
    if (status === "entering" || status === "entered") {
      return css`
          transform: scale(0, 0);
          animation: ${ImgFramesIn} 300ms ${MaterialStandard} forwards;
          animation-delay: ${p.delay + 100}ms;
        `;
    }
    if (status === "exiting") {
      return css`
          animation: ${FadeOut} 400ms ${MaterialStandard} forwards;
        `
    }
  }}
  }

  ${p => {
    if (p.bound) {
      return css`
        top: ${p.bound.top}px;
        left: ${p.bound.left}px;
        width: ${p.bound.width}px;
        height: ${p.bound.height}px;
      `;
    }
  }}

  ${p =>
    !p.isActive
      ? transform(p.theme.pageStatus, p.delay)
      : p.theme.pageStatus === "exiting" &&
      css`
          z-index: 99;
          position: fixed;
          animation: ${zoom} 300ms forwards;
          animation-delay: 150ms;
        `}
`;

const Icon = styled('div')`
  color: #ffffff;
  font-size: 9px;
  font-weight: 400;
  line-height: 60px;

  position: absolute;
  
  right: 0;
  bottom: 0;

  width: 0;
  height: 0;

  border-left: 25px solid transparent;
	border-bottom: 25px solid #15c503;

  svg {
    position: absolute;
    top: 13px;
    right: 2px;
  }
`

export const Tile = withTheme(props => {
  const isMatch = useRouteMatch(props.to);
  const [bound, setBound] = React.useState();
  const ref = React.useRef();
  const isCompleted = useIsCompleted(props.id)

  // Prevent 2 links being active when animation is disrupted
  React.useEffect(() => {
    if (props.theme.pageStatus !== "exiting" && !!bound) {
      setBound(null);
    }
  }, [props.theme.pageStatus]);

  React.useEffect(() => {
    if (isMatch && !bound && ref.current) {
      setBound(ref.current.getBoundingClientRect());
    }
  }, [isMatch, ref]);

  // Make a clone when active to prevent jumping because of position: fixed
  return (
    <>
      {!!bound && (
        <StyledTile bound={bound} isActive={true} {...props} ref={ref}>
          <Img src={props.img} alt="" />
        </StyledTile>
      )}
      <StyledTile
        as={props.disabled ? "div" : Link}
        ref={ref}
        isActive={false}
        isCompleted={isCompleted}
        {...props}
      >
        <Img src={props.img} alt="" />
        {/* {isCompleted && <Icon><FontAwesomeIcon icon={faCheck} /></Icon>} */}
        {isCompleted && <Check src={check} />}
      </StyledTile>
    </>
  );
});

