import React, { useState } from "react"
import useForm from "react-hook-form"

import { validate } from "../../App"
import { Button } from "../../dashboard/components/Button"
import { Content } from "../../dashboard/components/Content"
import Input from "../../dashboard/components/Input"
import { Text } from "../../dashboard/components/Text"
import { Title } from "../../dashboard/components/Title"
import Container from "../../game/components/Container"
import Footer from "../components/Footer"


export const FillInCode = ({ setValid }) => {
  const { register, errors, handleSubmit } = useForm()
  const [code, setCode] = useState("")
  const [error, setError] = useState()
  const onSubmit = (e) => {
    e.preventDefault()
    validate(code).then((valid) => {
      if (valid) {
        localStorage.setItem("code", code)
        setValid(valid)
      } else {
        setError(true)
      }
    })
  }
  return (
    <Container shouldCenter>
      <Content>
        <Title>Welkom bij Humania</Title>
        <Text>Meld je aan met de klassencode van je leerkracht</Text>
        <form onSubmit={onSubmit}>
          <Input
            label="Klassencode"
            value={code}
            name="code"
            placeholder="Vul je hier je 4-cijferige klassencode in"
            maxLength="4"
            onChange={(e) => setCode(e.target.value.toUpperCase())}
            error={error && { message: "Voer een geldige klassencode in" }}
          />
          <Button as="button" primary>
            Inschrijven
          </Button>
        </form>
        {/* <Space /> */}
      </Content>
      <Footer />
    </Container>
  )
}
