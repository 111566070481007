import React, { useMemo } from "react";
import useFetch from "fetch-suspense";
import {
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis
} from "recharts";

import { Content } from "../components/Content";
import { Box } from "../components/Box";
import { Circle } from "../components/Circle";

import { useStore } from "../store";

import IntroImage from '../../game/assignments/Assignment7/afbeelding3@2x.png'
import { RatioContainer } from "../../shared/components/RatioContainer";
import { GREY, BLUE, RED } from "../consts/colors";

import { renderCustomizedLabel } from "../components/SVGLabel";
import { InfoBox } from "../components/InfoBox";
import { NoData } from "../components/NoData";

import formatPercentage from '../../shared/utils/formatPercentage'

// TODO: Margin tussen legenda en grafiek

const TITLE = "Actie?"

export const Assignment7_1 = () => {
  const { user } = useStore();
  const stats = useFetch(`/api/stats/7/${user.code}`);
  const data = useMemo(
    () => [
      { name: "Sporter", expected: stats.school?.expected.sporter, result: stats.school?.result.sporter },
      { name: "Gamer", expected: stats.school?.expected.gamer, result: stats.school?.result.gamer },
      { name: "Bankhanger", expected: stats.school?.expected.bankhanger, result: stats.school?.result.bankhanger },
    ],
    [stats]
  );


  if (!stats.school) return <NoData title={TITLE} />


  return (
    <Content grid={6} title={TITLE}>
      <Box size={4} center>
        <RatioContainer ratio={2/3}>
          <ResponsiveContainer>
            <BarChart width={400} height={400} data={data}
            margin={{
              top: 65, right: 30, left: 20, bottom: 35,
            }}>
              <XAxis dataKey="name" stroke={GREY} />
              {/* <YAxis stroke={GREY} label={{value: "Percentage", position: "insideTopLeft", dy: -50, fill: GREY }} /> */}
              <Bar dataKey="expected" fill={BLUE[0]} name="Wat dacht iedereen te zijn" label={renderCustomizedLabel(formatPercentage)} />
              <Bar dataKey="result" fill={RED[0]} name="Wat de klas eigenlijk is" label={renderCustomizedLabel(formatPercentage)} />
              <Legend verticalAlign="bottom" fill={GREY} layout="vertical" wrapperStyle={{"bottom": 0}} />
            </BarChart>
          </ResponsiveContainer>
        </RatioContainer>
      </Box>
      <InfoBox size={2}>
        <Circle src={IntroImage} />
        <h3>Welk profiel had jij?</h3>
        <p>Dit is een overzicht van wat jullie dachten te zijn, en wat jullie werkelijk zijn. Gamers, sporters, of bankhangers. </p>
      </InfoBox>
    </Content>
  );
};
