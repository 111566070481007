import React from "react";
import styled from "styled-components/macro";

import { Box } from "./Box";
import { Space } from "../../dashboard/components/Space";

const Border = styled("div")`
  border: 1px solid #333333;
  padding: 8px;
  border-radius: 50%;
  width: 100%;
  position: relative;
  margin-bottom: 1vh;
  box-sizing: border-box;

  &::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

export const Circle = styled("div")`
  border-radius: 50%;
  color: #333333;
  background-color: white;
  font-family: "Work Sans";
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled("div")`
  color: #333333;
  flex: 1;
  /* font-size: 1.5vw; */
  font-weight: 400;
  line-height: 1.2;
  /* text-transform: uppercase; */
  text-align: center;
  margin-bottom: ${p => p.marginBottom || 0};
`;

export const Bubble = ({ size, top, bottom }) => (
  <Box size={size || 1} center>
    {!bottom && <Space />}
    <Border>
      <Circle>
        <span>{top?.value}</span>
      </Circle>
    </Border>
    <Label marginBottom={"3vh"}>{top?.label}</Label>
    {bottom && (
      <>
        <Border>
          <Circle>
            <span>{bottom?.value}</span>
          </Circle>
        </Border>
        <Label>{bottom?.label}</Label>
      </>
    )}
  </Box>
);
