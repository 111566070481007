import styled, { css } from 'styled-components/macro';
import Title from './Title';
import React from 'react'

const Content = styled('div')`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${p => p.padding || "13px"};
  padding-top: ${p => p.paddingTop || "60px"};
  padding-bottom: ${p => p.paddingBottom || "80px"};

  ${p => p.shouldCenter && css`
    align-items: center;
    justify-content: center;
  `}
  ${p => p.shouldScroll && css`overflow: auto;`}
  -webkit-overflow-scrolling: touch;
  z-index: 2;
  flex: 1;
`

export default Content;


const Space = styled('div')`
  flex: 1 1 auto;
`

export const CircleContent = ({children, header, footer, ...props}) => (
  <Content {...props}>
    <Space>{header}</Space>
    {children}
    <Space>{footer}</Space>
  </Content>
)
