import React from "react";
import { TransitionGroup, Transition } from "react-transition-group";
import styled, { css, keyframes } from "styled-components/macro";
import { CircleSpinner } from "react-spinners-kit";

import { Container } from "../components/Container";
import { Content } from "../components/Content";
import { Navigation } from "../components/Navigation";

import { MaterialStandard } from "../../shared/consts/ease";

import { Provider, useStore } from "../store";
import { useRef } from "react";
import { useEffect } from "react";
import { GREY } from "../consts/colors";

let currentPageIndex

const PageContainer = styled('div')`
  flex: 1;
  /*  */
  position: relative;
`

const animate = (from, to) => {
  return css`
    animation: forwards 200ms ${keyframes`
      from {
        transform: translateX( ${from} )
      }
      to {
        transform: translateX( ${to} )
      }
    `} ${MaterialStandard};
  `
}

const PageAnimation = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  
  ${p => {
    if (p.direction.current === 1) {
      switch (p.status) {
        case 'exiting': return animate('0', '-100vw')
        case 'entering': return animate('100vw', '0')
      }
    } else {
      switch (p.status) {
        case 'exiting': return animate('0', '100vw')
        case 'entering': return animate('-100vw', '0')
      }
    }
  }}
`;

const SpinnerContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Page = () => {
  const { page, pageIndex } = useStore();
  const oldValue = useRef(0);
  const direction = useRef();

  useEffect(() => {
    direction.current = (pageIndex > oldValue.current) ? 1 : -1
    oldValue.current = pageIndex;
  }, [pageIndex]);

  return (
    <PageContainer>
      <TransitionGroup>
        <Transition timeout={200} key={pageIndex}>
          {status => (
            <PageAnimation status={status} pageIndex={pageIndex} direction={direction}>
              <React.Suspense fallback={<SpinnerContainer><CircleSpinner color={GREY} /></SpinnerContainer>}>
                {page}
              </React.Suspense>
            </PageAnimation>
          )}
        </Transition>
      </TransitionGroup>
    </PageContainer>
  );
};

export const Home = ({ user }) => {
  return (
    <Provider user={user}>
      <Container>
        <Page />
        <Navigation />
      </Container>
    </Provider>
  );
};
