import React from "react";
import styled from 'styled-components/macro';
import { Link } from "react-router-dom";

const StyledPrevButton = styled.button`
  align-items: center;
  background: none;
  background-repeat: no-repeat;
  border: none;
  display: flex;
  height: 33px;
  justify-content: center;
  padding: 0;
  outline: none;
  width: 33px;
  &:active, :focus{
    outline: none;
  }

  path {
    fill: ${p => p.color || p.theme.color};
  }
`;

const PrevImg = styled.svg`
  border: none;
  height: 20px;
  padding: 0;
  width: 11px;
`;

const StyledLink = styled(Link)`
  &:active, :focus{
    outline: none;
    background: none;
  }
`;

const PrevButton = ( props ) => (
  <StyledPrevButton {...props}>
    <PrevImg viewBox="0 0 23 41">
      <g>
        <path d="M21.02,40c-0.26,0-0.51-0.1-0.71-0.29L0.98,20.37L20.32,1.03c0.39-0.39,1.02-0.39,1.41,0s0.39,1.02,0,1.41L3.81,20.37
          l17.92,17.92c0.39,0.39,0.39,1.02,0,1.41C21.54,39.9,21.28,40,21.02,40z"/>
      </g>
    </PrevImg>
  </StyledPrevButton>
)

export default ({isFirst, setStep, ...props}) => {
  if (isFirst) {
    return <StyledLink to="/overview"><PrevButton {...props} /></StyledLink>
  }
  return <PrevButton onClick={() => setStep(s => s - 1)} {...props}></PrevButton>
}