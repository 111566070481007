import React, { useRef, useMemo } from "react";
import styled, { css } from "styled-components/macro";
import { Map } from "immutable";

import Content from "../../components/Content";
import Title from "../../components/Title";
import RowDiv from "../../components/RowDiv";
import ColumnDiv from "../../components/ColumnDiv";
import Subtitle from "../../components/Subtitle";
import Button from "../../components/Button";
import Text from '../../components/Text';

import { ScrollIndicator } from "./ScrollIndicator";

// import cupcakeImg from './cupcake@2x.png';
import familyImg from './assets/familie.jpg';
import moneyImg from './assets/geld.jpg';
import giftImg from './assets/kado.jpg';
import decorationImg from './assets/slinger.jpg';
import foodImg from './assets/sushi.jpg';
import cakeImg from './assets/taart.jpg';
import friendsImg from './assets/vrienden.jpg';
import BgImg from "./bg.svg";

const Header = styled("div")`
  height: 100px;
  margin-top: 15px;
  margin-bottom: 20px;
`;
const Footer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  padding-bottom: 26px;
`;

const StyledContent = styled(Content)`
  background-repeat: repeat-y;
  background-position: center;
  background-image: url(${BgImg});
  background-size: cover;
`;

const StyledColumn = styled(ColumnDiv)`
  align-items: center;
  /* ${p =>
    p.right &&
    css`
      background-color: #808ad1;
    `} */
  flex: 1;
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px;
  border: 2px solid #ffffff;
  border-radius: 50%;

  ${p =>
    p.src
      ? css`
          background-color: #808ad1;
        `
      : css``}
`;

const ItemBorder = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px;
  ${p =>
    p.selected &&
    css`
      border: 2px solid #ffffff;
    `}

  border-radius: 50%;

  width: 142px;
  height: 142px;

  flex: 0 0 auto;

  margin-bottom: 10px;

  opacity: ${p => (p.isPlaced ? "0.4" : 1)};
`;

const ItemImg = styled.div`
  box-sizing: border-box;
  background-size: contain;
  border-radius: 100%;
  width: 130px;
  height: 130px;
  background-position: center center;
  background-repeat: no-repeat;

  ${p =>
    p.src
      ? css`
          background-image: url(${p.src});
        `
      : css``}
`;

const ItemEmpty = styled.div`
  width: 118px;
  height: 118px;
  border: 2px dashed #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: "Work Sans";
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
`;

const TitleWrapper = styled.div`
  height: 50px;
`

const Item = props => (
  <ColumnDiv>
    <ItemBorder {...props}>
      <StyledItem src={props.src}>
        {props.src ? (
          <ItemImg src={props.src.img} />
        ) : (
            <ItemEmpty>{props.index}</ItemEmpty>
          )}
      </StyledItem>
    </ItemBorder>
    <Subtitle
      marginTop="-11px"
      isHidden={!props.src}
      marginBottom="15px"
      opacity={props.isPlaced ? 0.4 : 1}
    >
      {props.src ? props.src.label : 'label'}
    </Subtitle>
  </ColumnDiv>
);

export const items = [
  { "label": "familie", id: "pos1", "img": familyImg },
  { "label": "geld", id: "pos2", "img": moneyImg },
  { "label": "cadeaus", id: "pos3", "img": giftImg },
  { "label": "versiering", id: "pos4", "img": decorationImg },
  { "label": "eten", id: "pos5", "img": foodImg },
  { "label": "gebak", id: "pos6", "img": cakeImg },
  { "label": "vrienden", id: "pos7", "img": friendsImg }
];


const Results = ({ placed, setStep, stats }) => {
  const statsItems = useMemo(() => stats?.map(id => items.find(item => item.id === id)), [stats])
  return (
  <StyledContent shouldScroll>
    <Header>
      <Title>Wat vinden andere<br />NEMO-bezoekers het belangrijkst?</Title>
    </Header>
    <RowDiv>
      <StyledColumn>
        <TitleWrapper><Subtitle>Andere bezoekers</Subtitle></TitleWrapper>
        {statsItems?.map((item) => (
          <div key={item.label}>
            <Item src={item} />
          </div>
        ))}
      </StyledColumn>
      <StyledColumn right>
        <TitleWrapper><Subtitle>Jij</Subtitle></TitleWrapper>
        {(() => {
          let array = [];
          for (let i = 0; i < items.length; i++) {
            const item = placed.get(i);
            array.push(<Item src={items[item]} key={i} index={i + 1} />);
          }
          return array;
        })()}
      </StyledColumn>
    </RowDiv>
    <Footer>
      <Button onClick={() => {
        setStep(s => s + 1);
      } }>
        VERDER
      </Button>
    </Footer>
  </StyledContent>);
}

const Assignment = ({ setStep, onResult, stats }) => {
  const [selected, setSelected] = React.useState(null);
  const [placed, setPlaced] = React.useState(Map());
  const [isResultsVisible, setShowResults] = React.useState(false);

  if (isResultsVisible) {
    return <Results placed={placed} setStep={setStep} stats={stats} />;
  }

  return (
    <StyledContent shouldScroll>
      <Header>
        <Title>Wat is voor jou het belangrijkst?</Title>
        <Text marginBottom="25px">Plaats de afbeeldingen <br /> op jouw volgorde</Text>
      </Header>
      <RowDiv>
        <StyledColumn>
          <Subtitle>ITEMS</Subtitle>
          {items.map((img, i) => {
            const isPlaced = placed.contains(i);
            return (
              <Item
                src={img}
                selected={i === selected}
                onClick={() => {
                  if (isPlaced) return;
                  setSelected(i);
                }}
                key={i}
                isPlaced={isPlaced}
              />
            );
          })}
        </StyledColumn>
        <StyledColumn right>
          <Subtitle>RANK</Subtitle>
          {(() => {
            let array = [];
            for (let i = 0; i < items.length; i++) {
              const item = placed.get(i);
              array.push(
                <Item
                  src={items[item]}
                  key={i}
                  index={i + 1}
                  onClick={() => {
                    if (item !== undefined) {
                      setPlaced(p => p.delete(i));
                      return;
                    }
                    if (selected === null) return;

                    setPlaced(p => p.set(i, selected));
                    setSelected(null);
                  }}
                />
              );
            }
            return array;
          })()}
        </StyledColumn>
      </RowDiv>
      <Footer>
        <Button onClick={() => setStep(s => s + 1)}>Vier ik niet</Button>
        <Button
          marginTop="20px"
          disabled={placed.count() !== items.length}
          onClick={() => {
            const array = {}
            placed.forEach((value, key) => {
              array[items[value].id] = 7 - key
            })
            onResult(array)
            setShowResults(true);
            // ref.current.scrollTop = 0
          }}
        >
          {isResultsVisible ? "VERDER" : "KLAAR"}
        </Button>
      </Footer>
      <ScrollIndicator />
    </StyledContent>
  );
};

export default Assignment;
