import styled from 'styled-components/macro';
import reveal from '../animations/reveal';

const Text = styled.p`
  color: ${p => p.theme.color || `#ffffff`};
  font-family: "Work Sans";
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  margin: 0;
  margin-right: 13px;
  margin-left: 13px;
  margin-top: ${p => p.marginTop || `5px`};
  margin-bottom: ${p => p.marginBottom || "5px"};
  ${p => reveal(p.theme.pageStatus, p.delay)}
  text-transform: ${p => p.allCaps ? `uppercase` : undefined};
`;

export default Text;