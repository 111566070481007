import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

const HomeIcon = styled('a')`
  position: absolute;
  top: 20px;
  right: 20px;
  /* ${p => p.isAssignmentButton ? css`right: 20px;` : css`left: 20px;`} */

  svg {
    width: 20px;
    height: 21px;
    fill:none;
    stroke-width:2;
    stroke-linecap:round;
    stroke: ${p => p.color || p.theme.color || `#333333`};
  }
`;

const Home = ({ isAssignmentButton }) => (
  <svg viewBox="0 0 42 42">
    <g>
      {!isAssignmentButton ?
        <>
          <line className="st0" x1="2.4" y1="2.4" x2="39.6" y2="2.4" />
          <line className="st0" x1="2.4" y1="39.6" x2="39.6" y2="39.6" />
          <line className="st0" x1="2.4" y1="21" x2="39.6" y2="21" />
        </> : <>
          <line x1="5.4" y1="39.9" x2="5.4" y2="24.7" />
          <line x1="36.6" y1="39.9" x2="36.6" y2="24.7" />
          <polyline points="39.6,20.7 21,2.1 2.4,20.7" />
          <line x1="5.4" y1="39.9" x2="36.6" y2="39.9" />
        </>}
    </g>
  </svg>
);

export default ({ isAssignmentButton, color }) => (
  <Link
    to={isAssignmentButton ? `/overview` : `/dashboard`}
    component={HomeIcon}
    color={color}>
    <Home isAssignmentButton={isAssignmentButton} />
  </Link>
)