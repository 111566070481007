import React, { useMemo } from "react";
import useFetch from "fetch-suspense";
import { ResponsiveContainer, BarChart, Bar, YAxis, XAxis } from "recharts";
import styled from "styled-components";

import { Content } from "../components/Content";
import { Box } from "../components/Box";
import { Circle } from "../components/Circle";

import { useStore } from "../store";

import IntroImage from '../../game/assignments/Assignment11/route3.png'
import { RatioContainer } from "../../shared/components/RatioContainer";
import { items } from "../../game/assignments/Assignment11/Assignment";
import { GREY, BLUE } from "../consts/colors";
import { SVGImage } from "../components/SVGImage";

import { renderCustomizedLabel } from "../components/SVGLabel";
import { InfoBox } from "../components/InfoBox";
import { NoData } from "../components/NoData";

import formatPercentage from '../../shared/utils/formatPercentage'

const Item = props => {
  const id = props.payload.value;
  const item = items.find(item => item.id === id);
  return (
    <SVGImage
      id={id}
      x={props.x}
      y={props.y + 45}
      radius={40}
      label={item.label}
      img={item.img}
    />
  );
};

const TITLE = "Er is er één jarig..."

export const Assignment11 = () => {
  const { user } = useStore();
  const stats = useFetch(`/api/stats/11/${user.code}`);

  if (!stats.school) return <NoData title={TITLE} />
  return (
    <Content grid={6} title={TITLE} subtitle="Wat is het belangrijkste op je verjaardag?">
      <Box size={4} center>
        {/* <p style={{ textAlign: "center", marginTop: 20 }}>
          Wat is het belangrijkste op je verjaardag?
        </p> */}
        <RatioContainer ratio={2 / 3}>
          <ResponsiveContainer>
            <BarChart
              width={400}
              height={400}
              data={stats.school?.items}
              margin={{ left: 25, bottom: 125 }}
            >
              {/* <YAxis dataKey="amount" type="number" stroke={GREY} fill={GREY} /> */}
              <XAxis dataKey="name" tick={<Item />} stroke={GREY} fill={GREY} />

              <Bar
                dataKey="percentage"
                fill={BLUE[0]}
                label={renderCustomizedLabel(formatPercentage)}
              />
            </BarChart>
          </ResponsiveContainer>
        </RatioContainer>
      </Box>
      <InfoBox size={2}>
        <Circle src={IntroImage} />
        <h3>Zo ziet een verjaardag eruit</h3>
        <p>
          Dit is een overzicht van hoe de klas de Breinprikkelaar heeft
          gespeeld. Wat vonden jullie het moeilijkste hieraan?
        </p>
      </InfoBox>
    </Content>
  );
};
