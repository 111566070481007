

export const ASSIGNMENT1 = "assignment1"
export const ASSIGNMENT2 = "assignment2"
export const ASSIGNMENT3 = "assignment3"
export const ASSIGNMENT4 = "assignment4"
export const ASSIGNMENT5 = "assignment5"
export const ASSIGNMENT6 = "assignment6"
export const ASSIGNMENT7 = "assignment7"
export const ASSIGNMENT8 = "assignment8"
export const ASSIGNMENT9 = "assignment9"
export const ASSIGNMENT10 = "assignment10"
export const ASSIGNMENT11 = "assignment11"
export const ASSIGNMENT12 = "assignment12"
export const ASSIGNMENT13 = "assignment13"