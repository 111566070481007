import React, { createContext, useEffect } from "react";
import { useContext } from "react";

import { useState } from "react";
import { Title } from "./pages/Title";
import { Assignment3 } from "./pages/Assignment3";
import { Assignment6_1 } from "./pages/Assignment6_1";
import { Assignment6_2 } from "./pages/Assignment6_2";
import { Assignment7_1 } from "./pages/Assignment7_1";
import { Assignment7_2 } from "./pages/Assignment7_2";
import { Assignment8_1 } from "./pages/Assignment8_1";
import { Assignment10 } from "./pages/Assignment10";
import { Assignment13 } from "./pages/Assignment13";
import { Assignment11 } from "./pages/Assignment11";
import { useFullscreen } from "../shared/utils/useFullscreen";
import { End } from "./pages/End";

const Context = createContext();

export const Provider = ({user, ...props}) => {
  // const [isFullscreen, setFullscreen] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const pages = [
    <Title />,
    <Assignment3 />,
    <Assignment6_1 />,
    <Assignment6_2 />,
    <Assignment7_1 />,
    <Assignment7_2 />,
    <Assignment8_1 />,
    <Assignment10 />,
    <Assignment11 />,
    <Assignment13 />,
    <End />
  ];
  // useFullScreen("root", isFullscreen);
  const [isFullscreen, setFullscreen] = useFullscreen()
  
  useEffect(() => {
    const downHandler = ({ keyCode }) => {
      switch (keyCode) {
        case 39: setPageIndex(p => Math.min(p + 1, pages.length - 1))
          break
        case 37: setPageIndex(p => Math.max(p - 1, 0))
          break
      }
    }
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []);


  return (
    <Context.Provider
      {...props}
      value={{
        isFullscreen,
        setFullscreen,
        page: pages[pageIndex],
        pages,
        pageIndex,
        setPageIndex,
        user
      }}
    />
  );
};

export const useStore = () => {
  return useContext(Context);
};
