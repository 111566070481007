import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components/macro'

const Label = styled('label')`
  display: flex;
  flex-direction: column;
  color: #333333;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 25px;
  
  p {
    ${p => p.isInvalid && css`color: #e36a66;`}
    margin: 0;
    margin-bottom: 5px;
    font-weight: 500;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border: 2px solid #333333;
    background-color: #ffffff;
    padding: 13px 20px;
    font-size: 16px;

    ${p => p.isInvalid && css`border: #e36a66 2px solid;`}
    ${p => p.isValid && css`border: #abd68e 2px solid;`}
  }
`

const ErrorMessage = styled('div')`
  color: #e36a66;
`

const getFormattedError = (error, label) => {
  switch (error.type) {
    case "required":
      return `${label} is een verplicht veld`;
    case "isEmail":
      return "Het ingevoerde e-mailadres is niet valide";
    default:
      return error.message;
  }
}

const Input = forwardRef(({ label, error, isInvalid, isValid, ...props }, ref) => {
  return (
    <Label isInvalid={isInvalid} isValid={isValid}>
      <p>{label}</p>
      <input ref={ref} {...props} />
      {error && <ErrorMessage>{getFormattedError(error, label)}</ErrorMessage>}
    </Label>
  )
})


export default Input