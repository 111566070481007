import styled from 'styled-components/macro';
import reveal from '../animations/reveal';

const Subtitle = styled.h3`
  color: ${p => p.theme.color};
  font-family: "Work Sans";
  line-height: 20px;
  font-size: 17px;
  font-weight: 300;
  text-align: center;
  margin: 0;
  margin-right: 13px;
  margin-left: 13px;
  margin-top: ${p => p.marginTop || '0px'};
  margin-bottom: ${p => p.marginBottom || "5px"};
  ${p => reveal(p.theme.pageStatus, p.delay)}
  text-transform: uppercase;
  opacity:${p => p.opacity || 1};
  visibility:${p => p.isHidden ? `hidden` : `visible`};
`;

export default Subtitle;