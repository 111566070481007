
import React from 'react'
import { SVGText } from './SVGText';

export const renderCustomizedLabel = (format = s => s) => props => {
  const { x, y, value, viewBox, amount } = props;
  if (!value) return null
  return (
    <SVGText
      x={x + viewBox.width / 2}
      y={y + viewBox.height / 2}
      fill="white"
      dominantBaseline="central"
      textAnchor="middle"
    >
      {format(value)}
    </SVGText>
  );
};