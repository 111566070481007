import React, { useMemo } from "react";
import useFetch from "fetch-suspense";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  LineChart,
  Line,
  CartesianGrid
} from "recharts";

import { Content } from "../components/Content";
import { Box } from "../components/Box";
import { Circle } from "../components/Circle";
import { Bubble } from "../components/Bubble";

import { useStore } from "../store";

import IntroImage from '../../game/assignments/Assignment7/afbeelding3@2x.png'
import formatMilliseconds from "../../shared/utils/formatMilliseconds";
import { RatioContainer } from "../../shared/components/RatioContainer";
import { BLUE, GREY } from "../consts/colors";
import { InfoBox } from "../components/InfoBox";
import { NoData } from "../components/NoData";


const TITLE = "Reactie!"

export const Assignment7_2 = () => {
  const { user } = useStore();
  const stats = useFetch(`/api/stats/7/${user.code}`);
  const data = useMemo(
    () => [
      { name: "1", value: stats.school?.["score-1"] },
      { name: "2", value: stats.school?.["score-2"] },
      { name: "3", value: stats.school?.["score-3"] },
      { name: "4", value: stats.school?.["score-4"] },
      { name: "5", value: stats.school?.["score-5"] }
    ],
    [stats]
  );

  if (!stats.school) return <NoData title={TITLE} />

  return (
    <Content grid={6} title={TITLE}>
      <Box size={3} center>
        <RatioContainer ratio={2.5 / 3}>
          <ResponsiveContainer>
            <LineChart
              width={400}
              height={400}
              data={data}
              margin={{
                top: 65,
                right: 30,
                left: 20,
                bottom: 35
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" label={{value: "Rondes", position: "bottom", fill: GREY}} stroke={GREY} />
              <YAxis tickFormatter={formatMilliseconds} dataKey="value" label={{value: "Tijd", position: "top", dy: -30, fill: GREY}} stroke={GREY} />
              <Line strokeWidth={3} dataKey="value" stroke={BLUE[0]} type="monotone" />
            </LineChart>
          </ResponsiveContainer>
        </RatioContainer>
      </Box>
      <Bubble
        size={1}
        top={{
          label: "Snelste reactie snelheid webapp",
          value: formatMilliseconds(stats.school.fastest)
        }}
        bottom={{ label: "Te vroeg aantal taps", value: stats.school.to_early }}
      />
      <InfoBox size={2}>
        <Circle src={IntroImage} />
        <h3>Reactiesnelheid</h3>
        <p>
          Hier staan de gemiddelde reactie snelheden van de klas. Gevolgd door
          het snelste reactie vermogen. Zie je verschillen tussen de reactietijd
          van de app en de exhibit? Hoe zou dat komen?
        </p>
      </InfoBox>
    </Content>
  );
};
