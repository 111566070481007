import styled from "styled-components/macro";

import { MOBILE_BREAKPOINT } from "../../shared/consts/css-snippets";

export const Text = styled('p')`
  margin: 0;
  margin-bottom: 16px;
  font-size: 16px;
  font-family: "Work Sans";
  line-height: 1.4;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    font-size: 18px;
  }
`;
