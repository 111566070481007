import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const CrossIcon = styled("a")`
  position: absolute;
  right: 20px;
  top: 20px;

  svg {
    width: 20px;
    height: 21px;
    fill: none;
    stroke: ${p => p.color || p.theme.color || `#333333`};
    stroke-width: 2;
    stroke-linecap: round;
  }
`;

const Cross = () => (
  <svg viewBox="0 0 42 42">
    <line className="st0" x1="39.6" y1="2.4" x2="2.4" y2="39.6" />
    <line className="st0" x1="39.6" y1="39.6" x2="2.4" y2="2.4" />
  </svg>
);

export default ({ color, to }) => (
  <Link to={to || `/overview`} component={CrossIcon} color={color}>
    <Cross />
  </Link>
);
