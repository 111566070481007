
import React from 'react'
import styled from 'styled-components'

import { Content } from "../components/Content"
import { Box } from "../components/Box"
import { Circle } from "../components/Circle"

import IntroImage from '../images/intro.png'

const StyledContent = styled(Content)`
  
  h1 {
    font-size: 64px;
    font-weight: 400;
    margin: 0;
  }

  h2 {
    font-size: 28px;
    font-weight: 300;
    margin: 0;
  }
  
`

export const Title = () => {
  return (
    <StyledContent grid={2}>
      <Box size={1}>
        <Circle src={IntroImage} />
      </Box>
      <Box size={1} center>
        <h1>Humania Digiboard</h1>
        <h2>Zijn jullie uniek of niet?</h2>
      </Box>
    </StyledContent>
  )
}