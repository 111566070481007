import React, { useMemo } from "react";
import useFetch from "fetch-suspense";
import {
  ResponsiveContainer,
  Pie,
  PieChart,
  Legend,
  Cell
} from "recharts";

import { Content } from "../components/Content";
import { Box } from "../components/Box";
import { Circle } from "../components/Circle";

import { useStore } from "../store";

import IntroImage from '../../game/assignments/Assignment13/exibit.jpg'
import { RatioContainer } from "../../shared/components/RatioContainer";
import { BLUE, RED } from "../consts/colors";
import { InfoBox } from "../components/InfoBox";
import { SVGText } from "../components/SVGText";
import { NoData } from "../components/NoData";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props) => {
  const {
    cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload
  } = props
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <SVGText x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${payload.payload.name}: ${payload.payload.value}`}
    </SVGText>
  );
};

const TITLE = "Wat jij wilt"

export const Assignment13 = () => {
  const { user } = useStore();
  const stats = useFetch(`/api/stats/13/${user.code}`);
  const data = useMemo(
    () => [
      { name: "Ja", value: stats.school?.yes, fill: BLUE[0] },
      { name: "Nee", value: stats.school?.no, fill: RED[0] }
    ],
    [stats]
  );

  if (!stats.school) return <NoData title={TITLE} />
  
  return (
    <Content grid={6} title={TITLE} subtitle="Hoeveel keer is er op ja/nee geklikt?">
      <Box size={4} center>
        {/* <p style={{textAlign: "center"}}>Hoeveel keer is er op ja/nee geklikt?</p> */}
        <RatioContainer ratio={1/1.3}>
          <ResponsiveContainer>
            <PieChart width={400} height={400}>
              <Pie labelLine={false} data={data} stroke="none" label={renderCustomizedLabel} />
            </PieChart>
          </ResponsiveContainer>
        </RatioContainer>
      </Box>
      <InfoBox size={2}>
        <Circle src={IntroImage} />
        <h3>Ja of nee, wat denk jij?</h3>
        <p>Dit is een weergave van hoe de klas dacht een vrije wil te hebben, hoe meer er JA is gedrukt hoe minder vrije wil er is getoont in de opdracht. Wat vinden jullie, heb je een vrije wil?</p>
      </InfoBox>
    </Content>
  );
};
