import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Space } from "../../dashboard/components/Space";
import Button from "./Button";

const StyledMobileOnly = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  img {
    width: 76px;
    margin-bottom: 40px;
  }

  h2 {
    color: #333333;
    font-family: "Work Sans";
    font-size: 25px;
    font-weight: 300;
    margin: 0;
    margin-bottom: 18px;
  }
  h3 {
    color: #333333;
    font-family: "Work Sans";
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    margin: 0;
  }
  ${Button} {
    text-decoration: none;
    width: 150px;
    height: 50px;
    border-color: #333333;
    color: #333333;
    box-sizing: border-box;
    line-height: 2.9;
  }
`;

export const MessagePage = ({children, to}) => (
  <StyledMobileOnly>
    <Space />
    <div>
      {children}
    </div>
    <Space />
    <Link component={Button} to={to} as="a">
      OK
    </Link>
    <Space />
  </StyledMobileOnly>
);
