import React from 'react'
import img from './icon.svg'
import { MessagePage } from '../../components/MessagePage';


export const CodeExpired = () => (
  <MessagePage to="/">
    <img src={img} />
    <h2>Klassencode verlopen</h2>
    <h3>
      De toegang van deze klassencode<br/>
      is verlopen, de docent moet<br/>
      opnieuw een account aanmaken.
    </h3>
  </MessagePage>
);
