import React, { useMemo } from "react";
import useFetch from "fetch-suspense";
import { ResponsiveContainer, BarChart, Bar, YAxis, XAxis } from "recharts";

import { Content } from "../../components/Content";
import { Box } from "../../components/Box";
import { Circle } from "../../components/Circle";

import { useStore } from "../../store";

import { RatioContainer } from "../../../shared/components/RatioContainer";
import { BLUE, GREY } from "../../consts/colors";

import { renderCustomizedLabel } from "../../components/SVGLabel";
import { SVGImage } from "../../components/SVGImage";

import IntroImage from '../../../game/assignments/Assignment8/intro3.png'
import SliderImage from './slider.png'
import CircleImage from './circle.png'
import ExibitImage from './exibit.png'
import { InfoBox } from "../../components/InfoBox";
import { NoData } from "../../components/NoData";

import formatSeconds from '../../../shared/utils/formatSeconds'

const items = {
  test1: {
    label: "Test 1",
    img: SliderImage
  },
  test2: {
    label: "Test 2",
    img: CircleImage
  },
  time: {
    label: "Test 3",
    img: ExibitImage
  },
}

const Item = props => {
  const id = props.payload.value;
  const item = items[id];
  return (
    <SVGImage
      id={id}
      x={props.x}
      y={props.y + 45}
      radius={40}
      label={item.label}
      img={item.img}
    />
  );
};

const TITLE = "Prikkel je brein"

export const Assignment8_1 = () => {
  const { user } = useStore();
  const stats = useFetch(`/api/stats/8/${user.code}`);
  const data = useMemo(() => ([
    {id: "test1", time: stats.school?.test1},
    {id: "test2", time: stats.school?.test2},
    {id: "time", time: stats.school?.time},
  ]), [stats])

  if (!stats.school) return <NoData title={TITLE} />

  return (
    <Content grid={6} title={TITLE}>
      <Box size={4} center>
        <RatioContainer ratio={2/3} >
          <ResponsiveContainer>
            <BarChart
              width={400}
              height={400}
              data={data}
              margin={{ left: 50, bottom: 155 }}
            >
              {/* <YAxis dataKey="time" type="number" stroke={GREY} label={{value: "Tijd", position: "top", dy: -30, fill: GREY}} /> */}
              <XAxis dataKey="id" tick={<Item />} />

              <Bar dataKey="time" fill={BLUE[0]}
                label={renderCustomizedLabel(formatSeconds)} />
            </BarChart>
          </ResponsiveContainer>
        </RatioContainer>
      </Box>
      <InfoBox size={2}>
        <Circle src={IntroImage} />
        <h3>Breinbrekers</h3>
        <p>
          Dit is een overzicht van hoe de klas de Breinprikkelaar heeft
          gespeeld. Wat vonden jullie het moeilijkste hieraan?
        </p>
      </InfoBox>
    </Content>
  );
};
